Ext.define('Ext.ux.panel.PanelExd', {
    extend: 'Ext.panel.Panel',
    alias: 'widget.panelexd',
    config: {
        collapseExd: false
    },
    initComponent: function () {
        this.callParent(arguments);
    },
    applyCollapseExd: function (v) {
        Ext.defer(function () {
            if (v) {
                this.collapse();
            } else {
                this.expand();
            }
        }, 1, this);
        return v;
    }
});
