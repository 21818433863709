Ext.define('Ext.ux.ImageExd', {
    extend: 'Ext.Component',
    alias: 'widget.mimageexd',
    autoEl: {
        tag: 'img',
        src: Ext.BLANK_IMAGE_URL
    },
    onRender: function () {
        this.autoEl = Ext.apply({}, this.initialConfig, this.autoEl);
        this.callParent(arguments);
        this.el.on('load', this.onLoad, this);
        this.el.on('error', this.onError, this);
    },
    onLoad: function () {
        this.fireEvent('load', this);
    },
    onError: function () {
        this.fireEvent('error', this);
    },
    setSrc: function (src) {
        if (this.rendered) {
            this.el.dom.src = src;
        } else {
            this.src = src;
        }
    },
    getSrc: function (src) {
        return this.el.dom.src || this.src;
    }
});
