Ext.define('PanelReportingCzasSelect', {
    extend: 'Ext.container.Container',
    alias: "widget.panelreportingczasselect",
    config: {
        compactView: false
    },
    layout: {
        type: "hbox",
        align: "stretch"
    },
    store: null,
    valueField: "value",
    displayField: "text",
    stateField: "state",
    stateMapField: {
        prev_day: "day",
        prev_week: "week",
        prev_month: "month",
        prev_year: "year",
        current_day: "day",
        current_week: "week",
        current_month: "month",
        current_year: "year"
    },
    hideNowTypeSelect: false,
    stateValue: null,
    hideDayGranulate: false,
    value: null,
    useModSegmentCls: false,
    initComponent: function () {
        this.cid = this.id;
        this.items = [{
            xtype: "segmentedbutton",
            itemId: "buttonls",
            flex: 1,
            listeners: {
                scope: this,
                toggle: this.onButtonToogle
            }
        }, {
            xtype: "button",
            itemId: "buttonlsmenu",
            enableToggle: true,
            text: "..."
        }, {
            xtype: "button",
            itemId: "nowTypeSelect",
            ui: "default-toolbar",
            width: 100,
            text: Lang.raport_do_teraz,
            enableToggle: true,
            hidden: this.hideNowTypeSelect,
            listeners: {
                scope: this,
                toggle: this.onButtonToogle
            }
        }];
        this.store = Ext.create('Ext.data.Store', {
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: this.valueField,
                fields: [this.displayField, this.valueField, this.stateField]
            }),
            proxy: {
                type: 'memory',
                reader: {
                    type: "json"
                }
            }
        });
        this.callParent(arguments);
        this.buttonls = this.down("#buttonls");
        this.buttonlsmenu = this.down("#buttonlsmenu");
        this.nowTypeSelect = this.down("#nowTypeSelect");
        this.setValue(this.value);
        this.cinited = true;
        Ext.defer(this.loadData, 1, this);
    },
    afterRender: function () {
        this.callParent(arguments);
    },
    onResize: function (width, height, oldWidth, oldHeight) {
        this.callParent(arguments);
    },
    onDestroy: function () {
        if (this.menuc)
            this.menuc.destroy();
        this.callParent(arguments);
    },
    applyCompactView: function (v) {
        if (this.cinited) {
            Ext.defer(function () {
                this.changeViewCompact(v);
            }, 1, this);
        }
        return v;
    },
    loadData: function () {
        var datemanipulationtLs = [{
            value: "custom",
            text: Lang.niestandardowy
        }, {
            value: "current_day",
            text: Lang.dzisiaj
        }, {
            value: "current_week",
            text: Lang.biezacy_tydzien
        }, {
            value: "current_month",
            text: Lang.biezacy_miesiac
        }, {
            value: "current_year",
            text: Lang.biezacy_rok
        }, {
            value: "prev_day",
            text: Lang.wczoraj
        }, {
            value: "prev_week",
            text: Lang.poprzedni_tydzien
        }, {
            value: "prev_month",
            text: Lang.poprzedni_miesiac_datemanipulation
        }, {
            value: "prev_year",
            text: Lang.poprzedni_rok
        }];
        this.store.loadData(datemanipulationtLs);
        Ext.suspendLayouts();
        this.dataLoaded = true;
        this.createButtons();
        this.bindValue();
        Ext.resumeLayouts(true);
    },
    createButtons: function () {
        var items = [];
        for (var i = 0; i < this.store.getCount(); i++) {
            var row = this.store.getAt(i);
            var v = row.get(this.valueField);
            var showbutton = true;
            if (this.stateValue != null) {
                showbutton = false;
                if (v == "custom") {
                    showbutton = true;
                } else {
                    var sv = this.stateMapField[v];
                    if (sv != null) {
                        if (this.stateValue == sv) {
                            showbutton = true;
                        }
                    }
                }
            }
            if (this.hideDayGranulate) {
                if (v == "current_day" || v == "prev_day") {
                    showbutton = false;
                }
            }
            var btnProp = {
                ftype: "buttondata",
                hidden: !showbutton,
                hiddenByS: !showbutton,
                value: row.get(this.valueField),
                fvalue: row.get(this.valueField),
                text: row.get(this.displayField),
                scope: this,
                handler: this.onButtonClick
            };
            if (v == "custom") {
                btnProp.menu = this.createButtonMenuItem();
            }
            items.push(btnProp);
        }
        if (this.useModSegmentCls) {
            buttonls._lastCls = buttonls._middleCls;
        }
        this.buttonls.removeAll(true);
        this.buttonls.add(items);
        this.customBtn = this.down("[fvalue=custom]");
        this.buttondatals = this.query("[ftype=buttondata]");
        this.changeViewCompact(null);
    },
    createMenuC: function () {
        if (!this.menuc) {
            var mitems = [];
            for (var i = 0; i < this.store.getCount(); i++) {
                var row = this.store.getAt(i);
                var v = row.get(this.valueField);
                var showbutton = true;
                if (this.stateValue != null) {
                    showbutton = false;
                    if (v == "custom") {
                        showbutton = true;
                    } else {
                        var sv = this.stateMapField[v];
                        if (sv != null) {
                            if (this.stateValue == sv) {
                                showbutton = true;
                            }
                        }
                    }
                }
                if (this.hideDayGranulate) {
                    if (v == "current_day" || v == "prev_day") {
                        showbutton = false;
                    }
                }
                mitems.push({
                    ftype: "buttondatamenu",
                    value: row.get(this.valueField),
                    fvalue: row.get(this.valueField),
                    text: row.get(this.displayField),
                    hidden: !showbutton,
                    scope: this,
                    handler: this.onItemMenuCClick
                });
            }
            this.menuc = Ext.create('Ext.menu.Menu', {
                items: mitems
            });
            this.buttondatamenu = this.menuc.query("[ftype=buttondatamenu]");
        }
    },
    changeViewCompact: function (compactView) {
        if (compactView === null) {
            compactView = this.compactView;
        }
        this.compactView = compactView;
        Ext.suspendLayouts();
        if (compactView) {
            if (this.menuc)
                this.menuc.destroy();
            this.menuc = null;
            this.createMenuC();
            this.buttonlsmenu.setMenu(this.menuc);
            this.buttonlsmenu.setHidden(false);
            for (var i = 0; i < this.buttondatals.length; i++) {
                var btn = this.buttondatals[i];
                btn.setHidden(btn.fvalue != "custom");
            }
        } else {
            if (this.menuc)
                this.menuc.destroy();
            this.menuc = null;
            this.buttonlsmenu.setMenu(null);
            this.buttonlsmenu.setHidden(true);
            for (var i = 0; i < this.buttondatals.length; i++) {
                var btn = this.buttondatals[i];
                btn.setHidden(btn.hiddenByS);
            }
        }
        Ext.resumeLayouts(true);
    },
    onItemMenuCClick: function (item, e, opts) {
        Ext.suspendLayouts();
        var v = item.value;
        this.value.typeCzas = v;
        this.buttonls.setValue(v);
        this.updateClock();
        this.setupValue();
        this.updateStateButton();
        this.updateStateButton2();
        Ext.resumeLayouts(true);
    },
    onButtonToogle: function (c, b, s) {
        Ext.suspendLayouts();
        this.updateClock();
        this.setupValue();
        this.updateStateButton();
        this.updateStateButton2();
        Ext.resumeLayouts(true);
    },
    updateButtonSelectRange: function () {
        changeToFirstNotHidden = false;
        for (var i = 0; i < this.buttondatals.length; i++) {
            var btn = this.buttondatals[i];
            if (btn.hiddenByS) {
                if (btn.fvalue == this.buttonls.getValue()) {
                    changeToFirstNotHidden = true;
                }
            }
        }
        if (changeToFirstNotHidden) {
            for (var i = 0; i < this.buttondatals.length; i++) {
                var btn = this.buttondatals[i];
                if (!btn.hiddenByS && btn.fvalue != "custom") {
                    this.buttonls.setValue(btn.fvalue);
                    break;
                }
            }
        }
    },
    changeStateValue: function (stateValue) {
        this.stateValue = stateValue;
        if (this.dataLoaded) {
            Ext.suspendLayouts();
            for (var i = 0; i < this.buttondatals.length; i++) {
                var btn = this.buttondatals[i];
                if (this.stateValue != null) {
                    showbutton = false;
                    var v = btn.fvalue;
                    if (v == "custom") {
                        showbutton = true;
                    } else {
                        var sv = this.stateMapField[v];
                        if (sv != null) {
                            if (this.stateValue == sv) {
                                showbutton = true;
                            }
                        }
                    }
                }
                btn.setHidden(!showbutton);
                btn.hiddenByS = !showbutton;
            }
            this.updateButtonSelectRange();
            this.setupValue(true);
            this.updateStateButton();
            this.updateStateButton2();
            this.changeViewCompact(null);
            Ext.resumeLayouts(true);
        }
    },
    updateStateButton: function () {
        var customT = this.getDateToStringByType(this.value.dateLocal);
        this.customBtn.setText(customT);
    },
    updateStateButton2: function () {
        var dv = Lang.niestandardowy;
        var idx = this.store.findExact(this.valueField, this.value.typeCzas);
        if (idx >= 0) {
            var row = this.store.getAt(idx);
            if (this.value.typeCzas != "custom") {
                dv = row.get(this.displayField);
            }
        }
        this.buttonlsmenu.setText(dv);
        if (this.compactView) {
            if (this.value.typeCzas == "custom") {
                this.customBtn.setPressed(true);
                this.buttonlsmenu.setPressed(false);
            } else {
                this.customBtn.setPressed(false);
                this.buttonlsmenu.setPressed(true);
            }
        }
    },
    updateClock: function () {
        if (this.nowTypeSelect.pressed) {
            var d = new Date();
            d = moment(d).add(-1, "hours").toDate();
            this.nowTypeSelect.setText(Lang.czas_do + " " + Ext.Date.format(d, "H:00"));
        } else {
            this.nowTypeSelect.setText(Lang.raport_do_teraz);
        }
    },
    updateStateRefresh: function () {
        this.updateClock();
    },
    getDateToStringByType: function (v) {
        v = Ext.Date.parseDate(v, Date.patterns.ISO8601Long);
        var bv = this.stateValue;
        if (bv == null) {
            return Ext.Date.format(v, "Y-m-d");
        } else if (bv == "day") {
            return Ext.Date.format(v, "Y-m-d");
        } else if (bv == "week") {
            return Ext.Date.format(v, "Y") + " - W" + Ext.Date.format(v, "W");
        } else if (bv == "month") {
            return Ext.Date.format(v, "F, Y");
        } else if (bv == "year") {
            return Ext.Date.format(v, "Y");
        } else {
            return Ext.Date.format(v, "Y-m-d");
        }
    },
    setValue: function (value) {
        this.value = value;
        if (this.value != null) {
            var v = this.value;
            if (Ext.isArray(v)) {
                this.value = {};
                v = v.slice(0, Math.max(3, v.length));
                this.value.typeCzas = v[0];
                this.value.dateLocal = v[1];
                this.value.nowTypeSelect = v[2];
                this.value.dateLocalFrom = v[1];
                this.value.dateLocalTo = v[1];

            } else if (Ext.isString(v)) {
                this.value = {};
                v = v.split(",");
                v = v.slice(0, Math.max(3, v.length));
                this.value.typeCzas = v[0];
                this.value.dateLocal = v[1];
                this.value.nowTypeSelect = v[2];

                this.value.dateLocalFrom = v[1];
                this.value.dateLocalTo = v[1];

            }
        } else {
            this.value = {};
        }
        this.value = Ext.applyIf(this.value, {
            typeCzas: "current_day",
            dateLocal: Ext.Date.format(new Date(), Date.patterns.ISO8601Long),
            nowTypeSelect: false
        });

        this.value = Ext.applyIf(this.value, {
            dateLocalFrom: Ext.Date.format(new Date(), Date.patterns.ISO8601Long),
            dateLocalTo: Ext.Date.format(new Date(), Date.patterns.ISO8601Long)
        });
        if (this.value.typeCzas == null) {
            this.value.typeCzas = "current_day";
        }
        if (this.value.dateLocal == null) {
            this.value.dateLocal = Ext.Date.format(new Date(), Date.patterns.ISO8601Long);
        }
        if (this.value.dateLocalFrom == null) {
            this.value.dateLocalFrom = Ext.Date.format(new Date(), Date.patterns.ISO8601Long);
        }
        if (this.value.dateLocalTo == null) {
            this.value.dateLocalTo = Ext.Date.format(new Date(), Date.patterns.ISO8601Long);
        }
        var d = Ext.Date.parseDate(this.value.dateLocal, Date.patterns.ISO8601Long);
        this.value.dateLocal = Ext.Date.format(d, Date.patterns.ISO8601Short + " 00:00:00");
        var d1 = Ext.Date.parseDate(this.value.dateLocalFrom, Date.patterns.ISO8601Long);
        var d2 = Ext.Date.parseDate(this.value.dateLocalTo, Date.patterns.ISO8601Long);
        this.value.dateLocalFrom = Ext.Date.format(d1, Date.patterns.ISO8601Short + " 00:00:00");
        this.value.dateLocalTo = Ext.Date.format(d2, Date.patterns.ISO8601Short + " 23:59:59");
        if (this.dataLoaded) {
            this.bindValue();
        }
    },
    getValue: function () {
        return this.value;
    },
    setupValue: function (supressEvent) {
        this.value.typeCzas = this.buttonls.getValue();
        this.value.nowTypeSelect = this.nowTypeSelect.pressed;
        var d = Ext.Date.parseDate(this.value.dateLocal, Date.patterns.ISO8601Long);
        this.value.dateLocal = Ext.Date.format(d, Date.patterns.ISO8601Short + " 00:00:00");

        if (this.value.typeCzas == "custom") {
            if (this.stateValue == "day") {
                this.setupDay(d);
            } else if (this.stateValue == "week") {
                this.setupWeek(d);
            } else if (this.stateValue == "month") {
                this.setupMonth(d);
            } else if (this.stateValue == "year") {
                this.setupYear(d);
            }
        } else if (this.value.typeCzas == "current_day") {
            var dt = new Date();
            var dt = Ext.Date.add(dt, Ext.Date.DAY, 0);
            this.setupDay(dt);
        } else if (this.value.typeCzas == "current_week") {
            this.setupWeek(new Date);
        } else if (this.value.typeCzas == "current_month") {
            this.setupMonth(new Date);
        } else if (this.value.typeCzas == "current_year") {
            this.setupYear(new Date);
        } else if (this.value.typeCzas == "prev_day") {
            var dt = new Date();
            var dt = Ext.Date.add(dt, Ext.Date.DAY, -1);
            this.setupDay(dt);
        } else if (this.value.typeCzas == "prev_week") {
            var m = moment(new Date());
            m.add(-1, 'weeks');
            this.setupWeek(m.toDate());
        } else if (this.value.typeCzas == "prev_month") {
            var m = moment(new Date());
            m.add(-1, 'months');
            this.setupMonth(m.toDate());
        } else if (this.value.typeCzas == "prev_year") {
            var m = moment(new Date());
            m.add(-1, 'years');
            this.setupYear(m.toDate());
        }
        if (!supressEvent) {
            this.fireEvent("filterchange", this);
        }
    },
    setupDay: function (date) {
        this.value.dateLocal = Ext.Date.format(date, Date.patterns.ISO8601Short + " 00:00:00");
        this.value.dateLocalFrom = Ext.Date.format(date, Date.patterns.ISO8601Short + " 00:00:00");
        this.value.dateLocalTo = Ext.Date.format(date, Date.patterns.ISO8601Short + " 23:59:59");
    },
    setupWeek: function (date) {
        var m = moment(date);
        m.isoWeekday(1);
        this.value.dateLocal = Ext.Date.format(m.toDate(), Date.patterns.ISO8601Short + " 00:00:00");
        var m = moment(date);
        m.isoWeekday(1);
        this.value.dateLocalFrom = Ext.Date.format(m.toDate(), Date.patterns.ISO8601Short + " 00:00:00");
        m.isoWeekday(7);
        this.value.dateLocalTo = Ext.Date.format(m.toDate(), Date.patterns.ISO8601Short + " 23:59:59");
    },
    setupMonth: function (date) {
        var m = moment(date);
        m.startOf('month');
        this.value.dateLocal = Ext.Date.format(m.toDate(), Date.patterns.ISO8601Short + " 00:00:00");
        var m = moment(date);
        m.startOf('month');
        this.value.dateLocalFrom = Ext.Date.format(m.toDate(), Date.patterns.ISO8601Short + " 00:00:00");
        m.endOf('month');
        this.value.dateLocalTo = Ext.Date.format(m.toDate(), Date.patterns.ISO8601Short + " 23:59:59");
    },
    setupYear: function (date) {
        var m = moment(date);
        m.startOf('year');
        this.value.dateLocal = Ext.Date.format(m.toDate(), Date.patterns.ISO8601Short + " 00:00:00");
        var m = moment(date);
        m.startOf('year');
        this.value.dateLocalFrom = Ext.Date.format(m.toDate(), Date.patterns.ISO8601Short + " 00:00:00");
        m.endOf('year');
        this.value.dateLocalTo = Ext.Date.format(m.toDate(), Date.patterns.ISO8601Short + " 23:59:59");
    },
    bindValue: function () {
        Ext.suspendLayouts();
        this.buttonls.setValue(this.value.typeCzas);
        this.nowTypeSelect.setPressed(this.value.nowTypeSelect);
        this.updateButtonSelectRange();
        this.updateStateButton();
        this.updateStateButton2();
        Ext.resumeLayouts(true);
    },
    createButtonMenuItem: function () {
        var menu = null;

        menu = {
            showSeparator: false,
            plain: true,
            bodyPadding: 0,
            items: [{
                margin: 0,
                itemId: "datepicker",
                border: false,
                xtype: 'datepicker',
                value: new Date(),
                scope: this,
                handler: function (c) {
                    var d = c.getValue();
                    this.value.dateLocal = Ext.Date.format(d, Date.patterns.ISO8601Long);
                    this.updateStateButton();
                    this.setupValue();
                    Ext.menu.Manager.hideAll();
                }
            }, {
                itemId: "monthpicker",
                margin: 0,
                border: false,
                xtype: 'monthpicker',
                value: new Date(),
                ownerCP: this,
                onCancel: function (c) {
                    Ext.menu.Manager.hideAll();
                },
                onSelect: function (c) {
                    var d = c.getValue();
                    var d = new Date((d[0] + 1) + '/1/' + d[1]);
                    this.ownerCP.value.dateLocal = Ext.Date.format(d, Date.patterns.ISO8601Long);
                    this.ownerCP.updateStateButton();
                    this.ownerCP.setupValue();
                    Ext.menu.Manager.hideAll();
                },
                listeners: {
                    okclick: 'onSelect',
                    cancelclick: 'onCancel',
                    monthclick: 'onSelect'
                }
            }],
            listeners: {
                scope: this,
                beforeShow: function (c) {
                    var datepicker = c.down("#datepicker");
                    var monthpicker = c.down("#monthpicker");
                    var bv = this.stateValue;
                    if (bv == null) {
                        datepicker.setHidden(false);
                        monthpicker.setHidden(true);
                    } else if (bv == "day") {
                        datepicker.setHidden(false);
                        monthpicker.setHidden(true);
                    } else if (bv == "week") {
                        datepicker.setHidden(false);
                        monthpicker.setHidden(true);
                    } else if (bv == "month") {
                        datepicker.setHidden(true);
                        monthpicker.setHidden(false);
                    } else if (bv == "year") {
                        datepicker.setHidden(true);
                        monthpicker.setHidden(false);
                    } else {
                        datepicker.setHidden(false);
                        monthpicker.setHidden(true);
                    }
                    var d = Ext.Date.parseDate(this.value.dateLocal, Date.patterns.ISO8601Long);
                    datepicker.setValue(d);
                    monthpicker.setValue(d);
                }
            }
        };
        return menu;
    },
    showPicker: function () {
    },
    showMonthPicker: function () {
    }
});
