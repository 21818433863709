Ext.define("Ext.ux.WebSocketObjectExd", {
    extend: "Ext.util.Observable",
    websocketURLCurrent: null,
    websocketURL: "ws://127.0.0.1:9874/websocket",
    websocketURLS: "wss://127.0.0.1:9875/websocket",
    relativeToCurrentURL: true,
    urlParamsAdd: null,
    wsComCls: "default",
    binaryTypeDefault: null,
    messageJsonDefault: true,
    binaryTypeArray: "arraybuffer",
    binaryTypeBlob: "blob",
    autoReconnect: true,
    deferOpenTimeout: 1000,
    sendFrameMessageId: 0,
    receiveFrameMessageId: 0,
    constructor: function (config) {
        this.callParent(arguments);
    },
    destroy: function () {
        this.close();
        this.callParent(arguments);
    },
    open: function () {
        if (this.ws) {
            throw "WebSocketObject already open, illegal state!";
        }
        this.fireEvent("beforeopen", this);
        this.manualClose = false;
        if (this.relativeToCurrentURL) {
            var currentLoc = window.location;
            var newURL = null;
            if (currentLoc.protocol === "https:") {
                newURL = "wss:";
            } else {
                newURL = "ws:";
            }
            newURL += "//" + currentLoc.host;
            newURL += "/websocket";
            this.websocketURLCurrent = newURL;
        } else {
            if (window.location.protocol == "https:") {
                this.websocketURLCurrent = this.websocketURLS;
            } else {
                this.websocketURLCurrent = this.websocketURL;
            }
        }
        var wsURL = this.websocketURLCurrent;
        wsURL = wsURL + (wsURL.indexOf("?") >= 0 ? "&rnd=" : "?rnd=") + Math.random();
        this.urlParamsAdd = this.urlParamsAdd || {};
        this.urlParamsAdd.wsComCls = this.wsComCls;
        if (this.urlParamsAdd) {
            wsURL = wsURL + "&" + Ext.urlEncode(mapNullValuesEncodeOrDeleteAndMergeData(this.urlParamsAdd));
        }
        this.ws = new WebSocket(wsURL);
        if (this.binaryTypeDefault) {
            this.ws.binaryType = binaryTypeDefault;
        }
        this.ws.onopen = Ext.bind(this.onWsOpen, this);
        this.ws.onclose = Ext.bind(this.onWsClose, this);
        this.ws.onerror = Ext.bind(this.onWsError, this);
        this.ws.onmessage = Ext.bind(this.onWsMessage, this);
    },
    close: function () {
        this.manualClose = true;
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    },
    getState: function (asString) {
        if (this.ws) {
            if (this.ws.readyState == 0) {
                return asString ? "opening" : 0;
            } else if (this.ws.readyState == 1) {
                return asString ? "open" : 1;
            } else if (this.ws.readyState == 2) {
                return asString ? "closing" : 2;
            } else if (this.ws.readyState == 3) {
                return asString ? "close" : 3;
            }
            return this.ws.readyState;
        } else {
            return asString ? "empty" : 4;
        }
    },
    onWsOpen: function () {
        this.fireEvent("open", this);
    },
    onWsClose: function () {
        this.fireEvent("close", this);
        this.ws = null;
        if (this.wsPP && this.wsPP.timeoutId) {
            clearTimeout(this.wsPP.timeoutId);
            this.wsPP.timeoutId = 0;
        }
        if (this.wsPP) {
            var wsPP = this.wsPP;
            this.wsPP = null;
            if (wsPP.callback) {
                wsPP.callback.call(wsPP.scopeCallback, this, null, null, 0, false, this.sendFrameMessageId);
            }
        }
        if (!this.manualClose) {
            if (this.autoReconnect) {
                Ext.defer(function () {
                    if (!this.manualClose) {
                        if (this.autoReconnect) {
                            this.open();
                        }
                    }
                }, this.deferOpenTimeout, this);
            }
        }
    },
    onWsError: function () {
        this.fireEvent("error", this);
    },
    onWsMessage: function (evt) {
        var me = this;
        me.receiveFrameMessageId++;
        var edata = evt.data;
        if (me.messageJsonDefault) {
            try {
                edata = Ext.decode(edata);
            } catch (exception) {
                edata = null;
            }
        }
        me.fireEvent("message", me, edata, evt, me.receiveFrameMessageId, true, me.sendFrameMessageId);
        if (me.wsPP && me.wsPP.timeoutId) {
            clearTimeout(me.wsPP.timeoutId);
            me.wsPP.timeoutId = 0;
        }
        if (me.wsPP) {
            var wsPP = me.wsPP;
            me.wsPP = null;
            if (wsPP.callback) {
                wsPP.callback.call(wsPP.scopeCallback, me, edata, evt, me.receiveFrameMessageId, true, me.sendFrameMessageId);
            }
        }
    },
    send: function (message, callback, scopeCallback, timeout) {
        var me = this;
        if (me.ws) {
            me.sendFrameMessageId++;
            if (callback) {
                if (me.wsPP && me.wsPP.timeoutId) {
                    clearTimeout(me.wsPP.timeoutId);
                    me.wsPP.timeoutId = 0;
                }
                me.wsPP = {
                    callback: callback,
                    scopeCallback: scopeCallback,
                    timeoutId: 0
                };
                if (timeout) {
                    me.wsPP.timeoutId = Ext.defer(function () {
                        if (me.wsPP) {
                            var wsPP = me.wsPP;
                            me.wsPP = null;
                            if (wsPP.callback) {
                                wsPP.callback.call(wsPP.scopeCallback, me, null, null, 0, false, me.sendFrameMessageId);
                            }
                        }
                    }, timeout, me);
                }
            }
            if (!Ext.isString(message)) {
                message = Ext.encode(message);
            }
            me.ws.send(message);
            return true;
        } else {
            if (me.wsPP && me.wsPP.timeoutId) {
                clearTimeout(me.wsPP.timeoutId);
                me.wsPP.timeoutId = 0;
            }
            if (me.wsPP) {
                var wsPP = me.wsPP;
                me.wsPP = null;
                if (wsPP.callback) {
                    wsPP.callback.call(wsPP.scopeCallback, me, null, null, 0, false, me.sendFrameMessageId);
                }
            }
            return false;
        }
    }
});
