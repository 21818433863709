Ext.define('Ext.ux.form.field.ComboBoxExdBoolean', {
    extend: 'Ext.form.field.ComboBox',
    alias: 'widget.comboexdboolean',
    forceSelection: true,
    displayField: 'text',
    valueField: 'value',
    allowBlank: false,
    textTrue: "Tak",
    textFalse: "Nie",
    defaultValue: null,
    initComponent: function () {
        this.store = {
            type: 'store',
            model: Ext.define(this.id + 'model_comboexdboolean', {
                extend: 'Ext.data.Model',
                idProperty: "value",
                fields: ['text', 'value']
            }),
            proxy: {
                type: 'memory',
                reader: {
                    type: "json"
                }
            },
            data: [{
                text: this.textTrue,
                value: "true"
            }, {
                text: this.textFalse,
                value: "false"
            }]
        };
        this.callParent(arguments);
        if (this.value === null) {
            this.setValue2Default(this.defaultValue);
        }
    },
    getValue: function () {
        return this.callParent(arguments);
    },
    setValue: function (value, doSelect, valueNotFoundExd) {
        if (typeof arguments[1] === "string") {
            this.valueNotFoundExd = arguments[1];
        }
        if (typeof arguments[2] === "string") {
            this.valueNotFoundExd = arguments[2];
        }
        return this.callParent(arguments);
    },
    getValue2: function () {
        var row = this.findRecord(this.valueField, this.getValue());
        if (row === false) {
            return null;
        }
        var v = this.getValue();
        return v == "true" || v == true;
    },
    setValue2: function (v, valueNotFoundExd) {
        v = v ? v.toString() : "false".toString();
        if (valueNotFoundExd !== undefined)
            this.valueNotFoundExd = valueNotFoundExd;
        var row = this.findRecord(this.valueField, v);
        if (row !== false) {
            this.setValue(v);
        } else {
            this.setValue(null);
        }
    },
    getValue2Default: function () {
        var row = this.findRecord(this.valueField, this.getValue());
        if (row === false) {
            if (this.store.getCount() > 0) {
                row = this.store.getAt(0);
                this.setValue(row.get(this.valueField));
            } else
                this.setValue(null);
        }
        var v = this.getValue();
        return v == "true" || v == true;
    },
    setValue2Default: function (v, valueNotFoundExd) {
        v = v ? v.toString() : "false".toString();
        if (valueNotFoundExd !== undefined)
            this.valueNotFoundExd = valueNotFoundExd;
        var row = this.findRecord(this.valueField, v);
        if (row !== false) {
            this.setValue(v);
        } else {
            if (this.store.getCount() > 0) {
                row = this.store.getAt(0);
                this.setValue(row.get(this.valueField));
            } else
                this.setValue(null);
        }
    }
});
