var RegularExpressionMatchLetterAndSpace = "^[^'\"]*$";

window.createRandomUUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

window.getUserBrowserLang = () => {
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
    let shortLang = lang;
    if (shortLang.indexOf('-') !== -1)
        shortLang = shortLang.split('-')[0];
    if (shortLang.indexOf('_') !== -1)
        shortLang = shortLang.split('_')[0];
    return shortLang;
}

window.encodeQueryData = (data) => {
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}

window.checkExtensionFileUpload = (svalue, extensionActp) => {
    var ext = svalue;
    if (svalue.lastIndexOf(".") < 0) {
        return false;
    }
    ext = svalue.substring(svalue.lastIndexOf("."), svalue.length);
    ext = ext.toLowerCase();
    if (ext != extensionActp && svalue != "") {
        return false;
    } else
        return true;
}

window.checkExtensionsFileUpload = (file, extArray) => {
    //extArray = new Array(".gif", ".jpg", ".png");
    allowSubmit = false;
    if (!file)
        return;
    while (file.indexOf("\\") != -1)
        file = file.slice(file.indexOf("\\") + 1);
    ext = file.slice(file.indexOf(".")).toLowerCase();
    for (var i = 0; i < extArray.length; i++) {
        if (extArray[i] == ext) {
            allowSubmit = true;
            break;
        }
    }
    if (allowSubmit)
        return true;
    else
        return false;
}

window.mapNullValuesDelete = (params) => {
    for (var l in params) {
        if (params[l] === null || params[l] === undefined) {
            delete params[l];
        }
    }
    return params;
}

window.mapNullValuesEncodeOrDelete = (params) => {
    for (var l in params) {
        if (params[l] === null || params[l] === undefined) {
            delete params[l];
        }
        if (Ext.isDate(params[l])) {
            params[l] = Ext.Date.format(params[l], "Y-m-d H:i:s");
        } else if (Ext.isObject(params[l]) || Ext.isArray(params[l])) {
            params[l] = Ext.encode(params[l]);
        }
    }
    return params;
}

window.mapNullValuesEncodeOrDeleteAndMergeData = (params, overflowdata) => {
    var paramsA = {};
    for (var l in params) {
        if (params[l] === null || params[l] === undefined) {
            delete params[l];
        }
        if (Ext.isDate(params[l])) {
            params[l] = Ext.Date.format(params[l], "Y-m-d H:i:s");
        }
        if (l != "data")
            paramsA[l] = params[l];
    }
    if (params["data"] === undefined) {
        params["data"] = paramsA;
    } else if (!Ext.isArray(params["data"])) {
        if (overflowdata) {
            params["data"] = {};
            Ext.apply(params["data"], paramsA);
        } else {
            Ext.applyIf(params["data"], paramsA);
        }
    }
    for (var l in params) {
        if (Ext.isObject(params[l]) || Ext.isArray(params[l])) {
            params[l] = Ext.encode(params[l]);
        }
    }
    return params;
}

window.generateCollectionOrder = (storedata, listidsel, idnameprop) => {
    var containsls = [];
    var containsls2 = [];
    var restls = [];
    Ext.each(storedata, function (v) {
        var vv = v[idnameprop];
        if (Ext.Array.contains(listidsel, vv))
            containsls.push(v);
        else
            restls.push(v);
    });
    Ext.each(listidsel, function (vv) {
        var ell = null;
        Ext.each(containsls, function (v) {
            if (vv == v[idnameprop]) {
                ell = v;
            }
        });
        if (ell != null)
            containsls2.push(ell);
    });
    var ls = containsls2.concat(restls);
    return ls;
}

window.generateDiffCollection = (storedata, listidsel, idnameprop) => {
    var containsls = [];
    var restls = [];
    Ext.each(storedata, function (v) {
        var vv = v[idnameprop];
        if (Ext.Array.contains(listidsel, vv))
            containsls.push(v);
        else
            restls.push(v);
    });
    return {
        containsls: containsls,
        restls: restls
    };
}

window.generateLSForObjectValueTextFromArray = (lsa) => {
    var lsa2 = [];
    for (var i = 0; i < lsa.length; i++) {
        lsa2.push({
            value: i,
            text: lsa[i]
        });
    }
    return lsa2;
}

window.toHex = (N) => {
    if (N == null)
        return "00";
    N = parseInt(N);
    if (N == 0 || isNaN(N))
        return "00";
    N = Math.max(0, N);
    N = Math.min(N, 255);
    N = Math.round(N);
    return "0123456789ABCDEF".charAt((N - N % 16) / 16) + "0123456789ABCDEF".charAt(N % 16);
}

window.getTimeZone = () => {
    var now = new Date().toString();
    var offset = -new Date().getTimezoneOffset();
    var TZ = now.indexOf('(') > -1 ? now.match(/\([^\)]+\)/)[0].match(/[A-Z]/g).join('') : now.match(/[A-Z]{3,4}/)[0];
    if (TZ == "GMT" && /(GMT\W*\d{4})/.test(now))
        TZ = RegExp.$1;
    var rightNow = new Date();
    var jan1 = new Date(rightNow.getFullYear(), 0, 1, 0, 0, 0, 0);
    var temp = jan1.toGMTString();
    var jan2 = new Date(temp.substring(0, temp.lastIndexOf(" ") - 1));
    var std_time_offset = -(jan2 - jan1) / (1000 * 60);
    var june1 = new Date(rightNow.getFullYear(), 6, 1, 0, 0, 0, 0);
    temp = june1.toGMTString();
    var june2 = new Date(temp.substring(0, temp.lastIndexOf(" ") - 1));
    var daylight_time_offset = -(june1 - june2) / (1000 * 60);
    var dst;
    if (std_time_offset == daylight_time_offset) {
        dst = false;
    } else {
        dst = true;
    }
    return {
        tz: TZ,
        tzoffset: offset,
        tzoffsetstd: std_time_offset,
        dst: dst
    };
}

window.handleStandardException = (callbackOrE, scope, defaultvalue) => {
    if (Ext.isFunction(callbackOrE)) {
        try {
            var retv = callbackOrE.call(scope);
            return retv;
        } catch (exp) {
            if (console && console.exception)
                console.exception(exp);
            var etxt = exp + ":" + printStackTrace({
                e: exp
            });
            alert(etxt);
            return defaultvalue;
        }
    } else {
        if (console && console.exception)
            console.exception(callbackOrE);
        var etxt = callbackOrE + ":" + printStackTrace({
            e: callbackOrE
        });
        alert(etxt);
    }
}

window.arrayFind = (array, pn, vn) => {
    for (var i = 0; i < array.length; i++) {
        var yc = array[i];
        if (Ext.isObject(yc)) {
            if (yc[pn] == vn) {
                return yc;
            }
        }
    }
    return null;
}

window.arrayFindIndex = (array, pn, vn) => {
    for (var i = 0; i < array.length; i++) {
        var yc = array[i];
        if (Ext.isObject(yc)) {
            if (yc[pn] == vn) {
                return i;
            }
        }
    }
    return -1;
}

window.getDataFromStore = (store, useSource, cols) => {
    var data = [];
    var sdata = store.getData();
    if (useSource) {
        sdata = store.getData().getSource() || store.getData();
    }
    for (var i = 0; i < sdata.getCount(); i++) {
        var srow = sdata.getAt(i);
        var dr = {};
        for (var k = 0; k < cols.length; k++) {
            dr[cols[k]] = srow.get(cols[k]);
        }
        data.push(dr);
    }
    return data;
}
