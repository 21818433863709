Ext.define('Ext.ux.form.LabelExd', {
    extend: 'Ext.form.Label',
    alias: 'widget.labelexd',
    baseCls: "x-ux-labelexd",
    initComponent: function () {
        this.callParent(arguments);
        if (this.value) {
            this.setValue(this.value);
        }
    },
    getValue: function () {
        return this.getText();
    },
    setValue: function (v) {
        this.setText(v);
    },
    getValue2: function () {
        return this.getValue();
    },
    setValue2: function (v) {
        this.setValue(v);
    },
    clearInvalid: function () {
        return this;
    },
    markInvalid: function (errors) {
    },
    isValid: function () {
        return true;
    },
    validate: function () {
        return true;
    },
    validateValue: function (v) {
        return true;
    },
    getErrors: function (v) {
        return [];
    }
});
