Ext.define('Ext.form.action.SubmitOverride', {
    override: 'Ext.form.action.Submit',
    onSuccess: function (response) {
        var form = this.form,
            formActive = form && !form.destroying && !form.destroyed,
            success = true;
        var result = this.processResponse(response);
        result = result || {};
        if (result !== true && !result.success) {
            if (result.errors && formActive) {
                form.markInvalid(result.errors);
            }
            this.failureType = Ext.form.action.Action.SERVER_INVALID;
            success = false;
        }
        if (formActive) {
            form.afterAction(this, success);
        }
    }
});
