Ext.define('Ext.ux.form.field.DateExd', {
    extend: 'Ext.form.field.Date',
    alias: 'widget.datefieldexd',
    getValue2: function () {
        if (this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "")) {
            return null;
        }
        return Ext.Date.format(this.getValue(), "Y-m-d H:i:s");
    },
    getValue2String: function () {
        if (this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "")) {
            return null;
        }
        return (this.getValue().trim ? this.getValue().trim() : this.getValue());
    },
    getValue2Default: function () {
        if (this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "")) {
            this.setValue(new Date());
            return Ext.Date.format(this.getValue(), "Y-m-d H:i:s");
        }
        return Ext.Date.format(this.getValue(), "Y-m-d H:i:s");
    },
    getValue2Default2: function () {
        if (this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "")) {
            this.setValue(new Date());
            return this.getValue();
        }
        return (this.getValue().trim ? this.getValue().trim() : this.getValue());
    },
    setValue2: function (v, vd) {
        if (vd != null) {
            v = v || vd;
        }
        if (!Ext.isDate(v) && v != null) {
            v = Ext.Date.parseDate(v, "Y-m-d H:i:s");
        }
        this.setValue(v === null ? "" : v);
    },
    setValue2String: function (v) {
        this.setValue(v === null ? "" : v);
    },
    setValue2Default: function (v) {
        this.setValue(v === null ? "" : v);
    },
    setValue2Default2: function (v) {
        this.setValue(v === null ? "" : v);
    }
});
