Ext.define('Ext.ux.tree.PanelExd', {
    extend: 'Ext.tree.Panel',
    alias: 'widget.treepanelexd',
    useAnimOnAutoEXC: false,
    initComponent: function () {
        if (!this.stateExpandPaths && !this.stateCollapsePaths && this.stateExColPathsId) {
            var stobj = Ext.state.Manager.get(this.stateExColPathsId);
            if (stobj != null) {
                this.stateExpandPaths = stobj.stateExpandPaths;
                this.stateCollapsePaths = stobj.stateCollapsePaths;
            }
        }
        if (!this.stateExpandPaths && !this.stateCollapsePaths) {
            this.stateExpandPaths = [];
            this.stateCollapsePaths = [];
        }
        Ext.ux.tree.PanelExd.superclass.initComponent.call(this);
        this.on('itemexpand', this.onExpand, this);
        this.on('itemcollapse', this.onCollapse, this);
        this.store.on('load', this.onStoreLoad, this);
        this.store.on('rootchange', this.onStoreLoad, this);
        this.expandCollpaseProcess = true;
    },
    setRootNode: function (root) {
        this.expandCollpaseProcess = true;
        Ext.ux.tree.PanelExd.superclass.setRootNode.call(this, root);
    },
    collapsePath: function (path, field, separator, callback, scope) {
        var me = this,
            current = me.getRootNode(),
            index = 1,
            view = me.getView(),
            keys,
            expander;
        field = field || me.getRootNode().idProperty;
        separator = separator || '/';
        if (Ext.isEmpty(path)) {
            Ext.callback(callback, scope || me, [false, null]);
            return;
        }
        keys = path.split(separator);
        if (current.get(field) != keys[1]) {
            Ext.callback(callback, scope || me, [false, current]);
            return;
        }
        collapser = function () {
            if (++index === keys.length) {
                Ext.callback(callback, scope || me, [true, current]);
                return;
            }
            var node = current.findChild(field, keys[index]);
            if (!node) {
                Ext.callback(callback, scope || me, [false, current]);
                return;
            }
            current = node;
            current.collapse(false, collapser);
        };
        current.collapse(false, collapser);
    },
    onStoreLoad: function () {
        this.view.animate = this.useAnimOnAutoEXC;
        this.expandCollpaseProcess = true;
        for (var i = 0; i < this.stateCollapsePaths.length; i++) {
            try {
                var path = this.stateCollapsePaths[i];
                this.treePanel.collapsePath(path, null, null, function () {
                }, this);
            } catch (e) {
            }
        }
        for (var i = 0; i < this.stateExpandPaths.length; i++) {
            try {
                var path = this.stateExpandPaths[i];
                this.expandPath(path, null, null, function () {
                }, this);
            } catch (e) {
            }
        }
        this.expandCollpaseProcess = false;
        this.view.animate = this.useAnimOnAutoEXC;
    },
    onExpand: function (node) {
        if (this.expandCollpaseProcess)
            return;
        var currentPath = node.getPath();
        Ext.Array.remove(this.stateExpandPaths, currentPath);
        Ext.Array.remove(this.stateExpandPaths, currentPath);
        this.stateExpandPaths.push(currentPath);
        if (this.stateExColPathsId)
            Ext.state.Manager.set(this.stateExColPathsId, {
                stateExpandPaths: this.stateExpandPaths,
                stateCollapsePaths: this.stateCollapsePaths
            });
    },
    onCollapse: function (node) {
        if (this.expandCollpaseProcess)
            return;
        var currentPath = node.getPath();
        Ext.Array.remove(this.stateExpandPaths, currentPath);
        Ext.Array.remove(this.stateExpandPaths, currentPath);
        this.stateCollapsePaths.push(currentPath);
        if (this.stateExColPathsId)
            Ext.state.Manager.set(this.stateExColPathsId, {
                stateExpandPaths: this.stateExpandPaths,
                stateCollapsePaths: this.stateCollapsePaths
            });
    }
});
