Ext.define('Ext.ux.grid.plugin.CellEditingExd', {
    extend: 'Ext.grid.plugin.CellEditing',
    clicksToEdit: 1,
    constructor: function () {
        this.callParent(arguments);
    },
    startEdit: function (record, columnHeader) {
        if (this.isCellEditable(record, columnHeader)) {
            return this.callParent(arguments);
        } else {
            return false;

        }
    },
    isCellEditable: function (record, columnHeader) {
        return false;
    }
});
