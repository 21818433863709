Ext.define('Ext.ux.form.field.DateExdFieldMod', {
    extend: 'Ext.ux.form.field.DateExd',
    alias: 'widget.datefieldexdmod',
    setValueExd: function (date) {
        Ext.ux.form.field.DateExdFieldMod.superclass.setValue.call(this, this.formatDate(date));
    },
    onSelect: function (m, d) {
        var vd = this.parseDate(d);
        if (this.getValue() != "" && this.getValue() != null) {
            var hm = Ext.Date.format(this.getValue(), "H");
            var im = Ext.Date.format(this.getValue(), "i");
            var sm = Ext.Date.format(this.getValue(), "s");
            var ym = Ext.Date.format(vd, "Y");
            var mm = Ext.Date.format(vd, "m");
            var dm = Ext.Date.format(vd, "d");
            this.setValueExd(Ext.Date.parseDate(ym + "-" + mm + "-" + dm + " " + hm + ":" + im + ":" + sm, "Y-m-d H:i:s"));
        } else {
            this.setValueExd(vd);
        }
        this.fireEvent('select', this, d);
        this.collapse();
    }
});

Ext.define('Ext.ux.form.field.DateExdFielModDo', {
    extend: 'Ext.ux.form.field.DateExd',
    alias: 'widget.datefieldexdmoddo',
    setValueExd: function (date) {
        Ext.ux.form.field.DateExdFielModDo.superclass.setValue.call(this, this.formatDate(date));
    },
    onSelect: function (m, d) {
        var vd = this.parseDate(d);
        if (this.getValue() != "" && this.getValue() != null) {
            var hm = Ext.Date.format(this.getValue(), "H");
            var im = Ext.Date.format(this.getValue(), "i");
            var sm = Ext.Date.format(this.getValue(), "s");
            var ym = Ext.Date.format(vd, "Y");
            var mm = Ext.Date.format(vd, "m");
            var dm = Ext.Date.format(vd, "d");
            this.setValueExd(Ext.Date.parseDate(ym + "-" + mm + "-" + dm + " " + hm + ":" + im + ":" + sm, "Y-m-d H:i:s"));
        } else {
            var ym = Ext.Date.format(vd, "Y");
            var mm = Ext.Date.format(vd, "m");
            var dm = Ext.Date.format(vd, "d");
            this.setValueExd(Ext.Date.parseDate(ym + "-" + mm + "-" + dm + " " + 23 + ":" + 59 + ":" + 59, "Y-m-d H:i:s"));
        }
        this.fireEvent('select', this, d);
        this.collapse();
    }
});
