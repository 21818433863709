Ext.define('Ext.ux.form.FieldLabelExd', {
    extend: 'Ext.form.FieldContainer',
    alias: 'widget.fieldlabelexd',
    layout: 'fit',
    textTrue: "Tak",
    textFalse: "Nie",
    plainStyle: false,
    baseCls: "x-ux-fieldlabelexd",
    tooltipConfig: {
        listeners: {
            'beforeshow': function (c) {
                c.update(this.ownerct.value);
            }
        }
    },
    initComponent: function () {
        this.items = [{
            xtype: "box",
            cls: "fieldbox"
        }];
        this.callParent(arguments);
        if (this.value) {
            this.setValue(this.value);
        }
    },
    afterRender: function () {
        this.callParent(arguments);
        if (this.plainStyle) {
            this.el.addCls("x-plain-style");
        }
        this.tooltipConfig.target = this.child().getEl();
        this.tooltipConfig.ownerct = this;
        this.tooltipel = Ext.create('Ext.tip.ToolTip', this.tooltipConfig);
        this.child().getEl().update(this.value != null ? this.value.toString() : null);
    },
    onDestroy: function () {
        if (this.tooltipel)
            this.tooltipel.destroy();
        this.callParent(arguments);
    },
    getValue: function () {
        return this.value;
    },
    setValue: function (v) {
        this.value = v;
        if (this.rendered) {
            this.child().getEl().update(v != null ? v.toString() : null);
        }
    },
    setValueT: function (value, langmap) {
        if (Ext.isBoolean(value)) {
            this.setValue(value ? this.textTrue : this.textFalse);
        } else if (Ext.isNumber(value)) {
            this.setValue(value);
        } else if (Ext.isNumeric(value)) {
            this.setValue(value);
        } else if (Ext.isString(value)) {
            this.setValue(value);
        } else if (langmap) {
            var svalue = langmap[value];
            this.setValue(svalue === null ? value : svalue);
        } else {
            this.setValue(value);
        }
    },
    getValue2: function () {
        return this.getValue();
    },
    setValue2: function (v) {
        this.setValue(v);
    },
    clearInvalid: function () {
        return this;
    },
    markInvalid: function (errors) {
    },
    isValid: function () {
        return true;
    },
    validate: function () {
        return true;
    },
    validateValue: function (v) {
        return true;
    },
    getErrors: function (v) {
        return [];
    }
});
