Ext.define('Ext.ux.form.field.TimeExd', {
    extend: 'Ext.form.field.Time',
    alias: 'widget.timefieldexd',
    getValue2: function () {
        if (this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "")) {
            return null;
        }
        return (this.getValue().trim ? this.getValue().trim() : this.getValue());
    },
    getValue2Default2: function () {
        if (this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "")) {
            this.setValue(new Date());
            return this.getValue();
        }
        return (this.getValue().trim ? this.getValue().trim() : this.getValue());
    },
    setValue2: function (v) {
        this.setValue(v === null ? "" : v);
    },
    setValue2Default2: function (v) {
        this.setValue(v === null ? "" : v);
    }
});
