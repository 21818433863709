String.prototype.capitalise = function () {
    return this.substring(0, 1).toUpperCase() + this.substring(1, this.length);
};

String.prototype.lowerlise = function () {
    return this.substring(0, 1).toLowerCase() + this.substring(1, this.length);
};

String.prototype.reverse = function () {
    var res = "";
    for (var i = this.length; i > 0; --i) {
        res += this.charAt(i - 1);
    }
    return res;
};

String.leftPad = function (val, size, ch) {
    var result = String(val);
    if (ch == null) {
        ch = " ";
    }
    while (result.length < size) {
        result = ch + result;
    }
    return result;
};

String.rightPad = function (val, size, ch) {
    var result = String(val);
    if (ch == null) {
        ch = " ";
    }
    while (result.length < size) {
        result = result + ch;
    }
    return result;
};

String.escape = function (string) {
    return string.replace(/('|\\)/g, "\\$1");
};

if (!('bind' in Function.prototype)) {
    Function.prototype.bind = function (owner) {
        var that = this;
        if (arguments.length <= 1) {
            return function () {
                return that.apply(owner, arguments);
            };
        } else {
            var args = Array.prototype.slice.call(arguments, 1);
            return function () {
                return that.apply(owner, arguments.length === 0 ? args : args.concat(Array.prototype.slice.call(arguments)));
            };
        }
    };
}

if (!('trim' in String.prototype)) {
    String.prototype.trim = function () {
        return this.replace(/^\s+/, '').replace(/\s+$/, '');
    };
}

if (!('indexOf' in Array.prototype)) {
    Array.prototype.indexOf = function (find, i) {
        if (i === undefined)
            i = 0;
        if (i < 0)
            i += this.length;
        if (i < 0)
            i = 0;
        for (var n = this.length; i < n; i++)
            if (i in this && this[i] === find)
                return i;
        return -1;
    };
}
if (!('lastIndexOf' in Array.prototype)) {
    Array.prototype.lastIndexOf = function (find, i) {
        if (i === undefined)
            i = this.length - 1;
        if (i < 0)
            i += this.length;
        if (i > this.length - 1)
            i = this.length - 1;
        for (i++; i-- > 0;)
            if (i in this && this[i] === find)
                return i;
        return -1;
    };
}
if (!('forEach' in Array.prototype)) {
    Array.prototype.forEach = function (action, that) {
        for (var i = 0,
                 n = this.length; i < n; i++)
            if (i in this)
                action.call(that, this[i], i, this);
    };
}
if (!('map' in Array.prototype)) {
    Array.prototype.map = function (mapper, that) {
        var other = new Array(this.length);
        for (var i = 0,
                 n = this.length; i < n; i++)
            if (i in this)
                other[i] = mapper.call(that, this[i], i, this);
        return other;
    };
}
if (!('filter' in Array.prototype)) {
    Array.prototype.filter = function (filter, that) {
        var other = [],
            v;
        for (var i = 0,
                 n = this.length; i < n; i++)
            if (i in this && filter.call(that, v = this[i], i, this))
                other.push(v);
        return other;
    };
}
if (!('every' in Array.prototype)) {
    Array.prototype.every = function (tester, that) {
        for (var i = 0,
                 n = this.length; i < n; i++)
            if (i in this && !tester.call(that, this[i], i, this))
                return false;
        return true;
    };
}
if (!('some' in Array.prototype)) {
    Array.prototype.some = function (tester, that) {
        for (var i = 0,
                 n = this.length; i < n; i++)
            if (i in this && tester.call(that, this[i], i, this))
                return true;
        return false;
    };
}

