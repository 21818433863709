Ext.define('Ext.ux.chart.LegendExd', {
    extend: 'Ext.view.View',
    alias: 'widget.legendexd',
    config: {
        tpl: ['<div class="', "x-ux-", 'legendexd-container">', '<tpl for=".">', '<div class="', "x-ux-", 'legendexd-item">', '<span ', 'class="', "x-ux-", 'legendexd-item-marker {[ values.disabled ? "x-ux-" + \'legendexd-inactive\' : \'\' ]}" ', 'style="background:{mark};">', '</span>{name}', '</div>', '</tpl>', '</div>'],
        nodeContainerSelector: 'div.' + "x-ux-" + 'legendexd-container',
        itemSelector: 'div.' + "x-ux-" + 'legendexd-item',
        baseCls: "x-ux-" + 'legendexd',
        padding: 5,
        disableSelection: true,
        toggleable: true,
        disableCountV: 1
    },
    initByRefO: function (chart) {
        this.chart = chart;
        var legendStore = chart.getLegendStore();
        this.bindStore(legendStore);
    },
    afterRender: function () {
        this.callParent(arguments);
    },
    onItemClick: function (record, item, index, e) {
        this.callParent(arguments);
        this.toggleItem(index);
    },
    toggleItem: function (index) {
        if (!this.getToggleable()) {
            return;
        }
        var store = this.getStore(),
            disabledCount = 0,
            disabled,
            canToggle = true,
            i,
            count,
            record;
        if (store) {
            count = store.getCount();
            for (i = 0; i < count; i++) {
                record = store.getAt(i);
                if (record.get('disabled')) {
                    disabledCount++;
                }
            }
            canToggle = count - disabledCount > this.disableCountV;
            record = store.getAt(index);
            if (record) {
                disabled = record.get('disabled');
                if (disabled || canToggle) {
                    record.set('disabled', !disabled);
                    this.fireEvent("serieschange", this, this.chart, record.get("series"), record.get("index"), record, store);
                }
            }
        }
    }
});
