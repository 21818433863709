Ext.define('Ext.ux.form.field.ComboBoxExd', {
    extend: 'Ext.form.field.ComboBox',
    alias: 'widget.comboexd',
    controlEmptyValue: true,
    controlEmptyValueAddRow: false,
    valueNotFoundExd: "<unknown>",
    valueNotFoundText: null,
    emptyText: '',
    editable: false,
    typeAhead: false,
    typeAheadDelay: 250,
    minChars: 4,
    selectOnFocus: false,
    forceSelection: false,
    triggerAction: 'all',
    queryMode: 'local',
    displayField: 'text',
    valueField: 'value',
    autoSortStore: true,
    autoForceToSelect: false,
    initComponent: function () {
        var me = this;
        //if (me.editable || me.config.editable) {
        //me.editable = !Ext.platformTags.touch;
        //me.config.editable = !Ext.platformTags.touch;
        //}
        if (this.controlEmptyValueOldS) {
            var displayTpl = me.displayTpl;
            if (!displayTpl) {
                var dspTplIfNotFoundS = '<tpl if="this.isEmptyValueUnknown(values)">{[this.toValueNotFoundExd()]}<tpl else>';
                var dspTplIfNotFoundE = "</tpl>";
                var dspTplIfFound = '{[typeof values === "string" ? values : values["' + me.displayField + '"]]}' + '<tpl if="xindex < xcount">' + me.delimiter + '</tpl>';
                this.config.displayTpl = me.displayTpl = new Ext.XTemplate('<tpl for=".">' + dspTplIfNotFoundS + dspTplIfFound + dspTplIfNotFoundE + '</tpl>', {
                    isEmptyValueUnknown: function (values) {
                        if (!this.cme.controlEmptyValueOldS)
                            return false;
                        if (typeof values === "string") {
                            return values === null || values === "" || values === undefined;
                        } else {
                            var vvvs = values[this.cme.valueField];
                            var dspv = values[this.cme.displayField];
                            if (dspv === null || dspv === "" || dspv === undefined) {
                                return false;
                            } else {
                                if (typeof vvvs === "string") {
                                    return vvvs === null || vvvs === "" || vvvs === undefined;
                                } else {
                                    return vvvs === null || vvvs === undefined;
                                }
                            }
                        }
                    },
                    toValueNotFoundExd: function () {
                        return this.cme.toValueNotFoundExd();
                    },
                    cme: this
                });
            } else if (!displayTpl.isTemplate) {
                this.config.displayTpl = me.displayTpl = new Ext.XTemplate(displayTpl);
            }
            this.toValueNotFoundExd = function () {
                return this.valueNotFoundExd;
            };
        }
        if (!this.store || this.store == "ext-empty-store") {
            var sorters = this.autoSortStore ? [{
                property: (typeof this.autoSortStore === "string") ? this.autoSortStore : this.displayField,
                direction: "ASC"
            }] : undefined;
            this.config.store = this.store = Ext.create('Ext.data.Store', {
                model: Ext.define(Ext.id() + "_model", {
                    extend: 'Ext.data.Model',
                    idProperty: this.valueField,
                    fields: [this.displayField, this.valueField]
                }),
                proxy: {
                    type: 'memory',
                    reader: {
                        type: "json"
                    }
                },
                sorters: sorters
            });
        }
        if (this.datas && this.store) {
            this.store.loadData(this.datas);
        }
        this.callParent(arguments);
        if (this.autoForceToSelect) {
            if (!this.store.isLoaded()) {
                this.store.on({
                    load: {
                        fn: function () {
                            this.setValue2Default(this.getValue());
                        },
                        scope: this,
                        single: true
                    }
                });
            } else {
                this.setValue2Default(this.getValue());
            }
        }
    },
    doSetValue: function (value, add) {
        var me = this,
            store = me.getStore(),
            Model = store.getModel(),
            matchedRecords = [],
            valueArray = [],
            autoLoadOnValue = me.autoLoadOnValue,
            isLoaded = store.getCount() > 0 || store.isLoaded(),
            pendingLoad = store.hasPendingLoad(),
            unloaded = autoLoadOnValue && !isLoaded && !pendingLoad,
            forceSelection = me.forceSelection,
            selModel = me.pickerSelectionModel,
            displayField = me.displayField,
            valueField = me.valueField,
            displayIsValue = displayField === valueField,
            isEmptyStore = store.isEmptyStore,
            lastSelection = me.lastSelection,
            i,
            len,
            record,
            dataObj,
            valueChanged,
            key,
            val;
        if (add && !me.multiSelect) {
            Ext.raise('Cannot add values to non multiSelect ComboBox');
        }
        if (pendingLoad || unloaded || !isLoaded || isEmptyStore) {
            if (!value.isModel) {
                if (add) {
                    me.value = Ext.Array.from(me.value).concat(value);
                } else {
                    me.value = value;
                }
                me.setHiddenValue(me.value);
                me.setRawValue(displayIsValue ? value : '');
                if (displayIsValue && !Ext.isEmpty(value) && me.inputEl && me.emptyText) {
                    me.inputEl.removeCls(me.emptyUICls);
                }
            }
            if (unloaded && !isEmptyStore) {
                store.load();
            }
            if (!value.isModel || isEmptyStore) {
                return me;
            }
        }
        value = add ? Ext.Array.from(me.value).concat(value) : Ext.Array.from(value);
        for (i = 0,
                 len = value.length; i < len; i++) {
            record = val = value[i];
            if (!record || !record.isModel) {
                record = me.findRecordByValue(key = record);
                if (!record) {
                    record = me.valueCollection.find(valueField, key);
                }
            }
            if (!record) {
                if (!forceSelection) {
                    if (!record && value[i]) {
                        dataObj = {};
                        if (Ext.isObject(val)) {
                            dataObj[displayField] = val[displayField];
                            dataObj[valueField] = val[valueField];
                        } else {
                            dataObj[displayField] = val;
                            if (valueField && displayField !== valueField) {
                                dataObj[valueField] = val;
                            }
                        }
                        // początek modyfikacji
                        if (me.controlEmptyValue) {
                            if (me.controlEmptyValueAddRow) {
                                var rown = me.createDefaultRowModel();
                                me.store.suspendEvents();
                                me.store.add(rown);
                                me.store.resumeEvents();
                                if (me.picker) {
                                    me.picker.refresh();
                                }
                                record = rown;
                            } else {
                                if (me.valueField && me.displayField !== me.valueField) {
                                    dataObj = {};
                                    dataObj[me.displayField] = me.valueNotFoundExd;
                                    dataObj[me.valueField] = value[i];
                                }
                                record = new Model(dataObj);
                            }
                        } else {
                            record = new Model(dataObj);
                        }
                        // koniec modyfikacji
                        // record = new Model(dataObj);
                    }
                } else if (me.valueNotFoundRecord) {
                    // początek modyfikacji
                    if (me.controlEmptyValue && value[i]) {
                        record = me.valueNotFoundRecord = me.createDefaultRowModel();
                    } else {
                        record = me.valueNotFoundRecord;
                    }
                    // koniec modyfikacji
                } else {
                    // początek modyfikacji
                    if (me.controlEmptyValue && value[i]) {
                        record = me.valueNotFoundRecord = me.createDefaultRowModel();
                    }
                    // koniec modyfikacji
                }
            }
            if (record) {
                matchedRecords.push(record);
                valueArray.push(record.get(valueField));
            }
        }
        if (lastSelection) {
            len = lastSelection.length;
            if (len === matchedRecords.length) {
                for (i = 0; !valueChanged && i < len; i++) {
                    if (Ext.Array.indexOf(me.lastSelection, matchedRecords[i]) === -1) {
                        valueChanged = true;
                    }
                }
            } else {
                valueChanged = true;
            }
        } else {
            valueChanged = matchedRecords.length;
        }
        if (valueChanged) {
            me.suspendEvent('select');
            me.valueCollection.beginUpdate();
            if (matchedRecords.length) {
                selModel.select(matchedRecords, false);
            } else {
                selModel.deselectAll();
            }
            me.valueCollection.endUpdate();
            me.resumeEvent('select');
        } else {
            me.updateValue();
        }
        return me;
    },
    updateValue: function () {
        var me = this,
            selectedRecords = me.valueCollection.getRange(),
            len = selectedRecords.length,
            valueArray = [],
            displayTplData = me.displayTplData || (me.displayTplData = []),
            inputEl = me.inputEl,
            i,
            record,
            displayValue;
        displayTplData.length = 0;
        for (i = 0; i < len; i++) {
            record = selectedRecords[i];
            displayTplData.push(me.getRecordDisplayData(record));
            // początek modyfikacji
            if (me.controlEmptyValue) {
                valueArray.push(record.get(me.valueField));
            } else {
                if (record !== me.valueNotFoundRecord) {
                    valueArray.push(record.get(me.valueField));
                }
            }
            // koniec modyfikacji
        }
        me.setHiddenValue(valueArray);
        me.value = me.multiSelect ? valueArray : valueArray[0];
        if (!Ext.isDefined(me.value)) {
            me.value = undefined;
        }
        me.displayTplData = displayTplData;
        displayValue = me.getDisplayValue();
        me.setRawValue(displayValue);
        me.refreshEmptyText();
        me.checkChange();
        if (!me.lastSelectedRecords && selectedRecords.length) {
            me.lastSelectedRecords = selectedRecords;
        }
        if (inputEl && me.typeAhead && me.hasFocus) {
            me.selectText(displayValue.length);
        }
    },
    checkValueOnChange: function () {
        var me = this;
        if (!me.destroying && me.getStore().isLoaded()) {
            if (me.multiSelect) {
            } else {
                if (me.forceSelection && !me.changingFilters && !me.findRecordByValue(me.value)) {
                    if (me.queryMode != 'local' && (me.hasFocus || me.isPaging)) {
                        return;
                    }
                    // początek modyfikacji
                    if (me.controlEmptyValue) {
                        me.setValue(me.value);
                    } else {
                        me.setValue(null);
                    }
                    // koniec modyfikacji
                }
            }
        }
    },
    autoSize: function () {
        var me = this,
            triggers,
            triggerId,
            triggerWidth,
            inputEl,
            width,
            value;
        if (me.grow && me.rendered && me.getSizeModel().width.auto) {
            inputEl = me.inputEl;
            triggers = me.getTriggers();
            triggerWidth = 0;
            // początek modyfikacji
            value = Ext.util.Format.htmlEncode(me.getDisplayValueD() || me.getGrowWidth() || (me.hasFocus ? '' : me.emptyText) || '');
            // koniec modyfikacji
            value += me.growAppend;
            for (triggerId in triggers) {
                triggerWidth += triggers[triggerId].el.getWidth();
            }
            width = inputEl.getTextWidth(value) + triggerWidth + me.inputWrap.getBorderWidth('lr') + me.triggerWrap.getBorderWidth('lr');
            width = Math.min(Math.max(width, me.growMin), me.growMax);
            me.bodyEl.setWidth(width);
            me.updateLayout();
            me.fireEvent('autosize', me, width);
        }
    },
    createDefaultRowModel: function () {
        var r = {};
        if (this.value) {
            if (this.valueField) {
                r[this.valueField] = this.value;
            }
        }
        if (this.displayField !== this.valueField) {
            r[this.displayField] = this.valueNotFoundExd;
        }
        var rown = new this.store.model(r);
        return rown;
    },
    getValue: function () {
        return this.callParent(arguments);
    },
    setValueWithReload: function (value, valueNotFoundExd) {
        this.store.removeAll();
        this.store.load();
        this.setValue(value, valueNotFoundExd);
    },
    setValue: function (value, valueNotFoundExd) {
        if (typeof arguments[1] === "string") {
            this.valueNotFoundExd = arguments[1];
        }
        if (typeof arguments[2] === "string") {
            this.valueNotFoundExd = arguments[2];
        }
        return this.callParent(arguments);
    },
    getValue2: function () {
        var row = this.findRecord(this.valueField, this.getValue());
        if (row === false) {
            return null;
        }
        return this.getValue();
    },
    setValue2: function (v, valueNotFoundExd) {
        if (valueNotFoundExd !== undefined) {
            this.valueNotFoundExd = valueNotFoundExd;
        }
        var row = this.findRecord(this.valueField, v);
        if (row !== false) {
            this.setValue(v);
        } else {
            this.setValue(null);
        }
    },
    setValue2IfExist: function (v, valueNotFoundExd) {
        if (valueNotFoundExd !== undefined) {
            this.valueNotFoundExd = valueNotFoundExd;
        }
        var row = this.findRecord(this.valueField, v);
        if (row !== false) {
            this.setValue(v);
            return true;
        }
        return false;
    },
    getValue2Default: function () {
        var row = this.findRecord(this.valueField, this.getValue());
        if (row === false) {
            if (this.store.getCount() > 0) {
                row = this.store.getAt(0);
                this.setValue(row.get(this.valueField));
            } else
                this.setValue(null);
        }
        return this.getValue();
    },
    getDisplayValueD: function () {
        var row = this.findRecord(this.valueField, this.getValue());
        if (row !== false) {
            return row.get(this.displayField);
        }
        return "";
    },
    checkValue2Default: function () {
        var row = this.findRecord(this.valueField, this.getValue());
        if (row === false) {
            if (this.store.getCount() > 0) {
                row = this.store.getAt(0);
                this.setValue(row.get(this.valueField));
            } else
                this.setValue(null);
        }
    },
    setValue2Default: function (v, valueNotFoundExd) {
        if (valueNotFoundExd !== undefined) {
            this.valueNotFoundExd = valueNotFoundExd;
        }
        var row = this.findRecord(this.valueField, v);
        if (row !== false) {
            this.setValue(v);
        } else {
            if (this.store.getCount() > 0) {
                row = this.store.getAt(0);
                this.setValue(row.get(this.valueField));
            } else
                this.setValue(null);
        }
    },
    getValue2NL: function () {
        if (this.getValue() == null || this.getValue() == "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() == "")) {
            return null;
        }
        return (this.getValue().trim ? this.getValue().trim() : this.getValue());
    },
    setValue2NL: function (v, valueNotFoundExd) {
        if (valueNotFoundExd !== undefined) {
            this.valueNotFoundExd = valueNotFoundExd;
        }
        if (v == null || v == "" || (v != null && v.trim && v.trim() == "")) {
            v = null;
        }
        this.setValue(v);
    },
    getRawValue2NL: function () {
        if (this.getRawValue() == null || this.getRawValue() == "" || (this.getRawValue() != null && this.getRawValue().trim && this.getRawValue().trim() == "")) {
            return null;
        }
        return (this.getRawValue().trim ? this.getRawValue().trim() : this.getRawValue());
    },
    getRow: function () {
        var r = this.findRecord(this.valueField, this.getValue());
        return r === false ? null : r;
    },
    getRow2: function (v) {
        var r = this.findRecord(this.valueField, v);
        return r === false ? null : r;
    },
    isRow: function () {
        return this.findRecord(this.valueField, this.getValue()) !== false;
    },
    isRow2: function (v) {
        return this.findRecord(this.valueField, v) !== false;
    },
    filterBy: function (fn, scope) {
        var ds = this.store;
        ds.filterBy(fn, scope);
        ds.realSnapshot = ds.snapshot;
        ds.snapshot = ds.data;
    },
    clearFilter: function (suppressEvent) {
        var ds = this.store;
        if (ds.realSnapshot && ds.realSnapshot != ds.snapshot) {
            ds.snapshot = ds.realSnapshot;
            delete ds.realSnapshot;
        }
        ds.clearFilter(suppressEvent);
    }
});
