Ext.define('Ext.ux.view.ButtonComboExd', {
    extend: 'Ext.container.Container',
    alias: 'widget.buttoncomboexd',
    config: {
        compactView: false
    },
    baseCls: "x-ux-buttoncomboexd",
    displayField: 'text',
    valueField: 'value',
    autoSortStore: true,
    autoForceToSelect: false,
    value: null,
    controlEmptyValue: true,
    valueNotFoundExd: "<unknown>",
    initComponent: function () {
        this.items = [{
            xtype: "segmentedbutton",
            itemId: "buttonls",
            flex: 1,
            listeners: {
                scope: this,
                toggle: this.onButtonToogle
            }
        }, {
            xtype: "button",
            itemId: "buttonlsmenu",
            text: "..."
        }];
        if (!this.store || this.store == "ext-empty-store") {
            var sorters = this.autoSortStore ? [{
                property: (typeof this.autoSortStore === "string") ? this.autoSortStore : this.displayField,
                direction: "ASC"
            }] : undefined;
            this.store = Ext.create('Ext.data.Store', {
                model: Ext.define(null, {
                    extend: 'Ext.data.Model',
                    idProperty: this.valueField,
                    fields: [this.displayField, this.valueField]
                }),
                proxy: {
                    type: 'memory',
                    reader: {
                        type: "json"
                    }
                },
                sorters: sorters
            });
        }
        this.callParent(arguments);
        this.buttonls = this.down("#buttonls");
        this.buttonlsmenu = this.down("#buttonlsmenu");
        if (this.datas && this.store) {
            this.store.loadData(this.datas);
        }
        if (this.store.isLoaded()) {
            this.storeLoad();
        }
        this.store.on("datachanged", this.storeLoad, this);
        if (this.autoForceToSelect) {
            if ((!this.store.isLoaded())) {
                this.store.on({
                    datachanged: {
                        fn: function () {
                            this.setValue2Default(this.getValue());
                        },
                        scope: this,
                        single: true
                    }
                });
            } else {
                this.setValue2Default(this.getValue());
            }
        }
        this.cinited = true;
    },
    onDestroy: function () {
        if (this.menuc)
            this.menuc.destroy();
        this.callParent(arguments);
    },
    applyCompactView: function (v) {
        if (this.cinited) {
            Ext.defer(function () {
                this.changeViewCompact(v);
            }, 1, this);
        }
        return v;
    },
    afterRender: function () {
        this.callParent(arguments);
    },
    onResize: function (width, height, oldWidth, oldHeight) {
        this.callParent(arguments);
    },
    storeLoad: function () {
        this.createButtons();
        this.bindValue();
    },
    createButtons: function () {
        var items = [];
        for (var i = 0; i < this.store.getCount(); i++) {
            var row = this.store.getAt(i);
            var v = row.get(this.valueField);
            var btnProp = {
                ftype: "buttondata",
                value: row.get(this.valueField),
                fvalue: row.get(this.valueField),
                text: row.get(this.displayField),
                record: row
            };
            items.push(btnProp);
        }
        this.buttonls.removeAll(true);
        this.buttonls.add(items);
        this.changeViewCompact(null);
    },
    createMenuC: function () {
        if (!this.menuc) {
            var mitems = [];
            for (var i = 0; i < this.store.getCount(); i++) {
                var row = this.store.getAt(i);
                var v = row.get(this.valueField);
                mitems.push({
                    ftype: "buttondatamenu",
                    value: row.get(this.valueField),
                    fvalue: row.get(this.valueField),
                    text: row.get(this.displayField),
                    record: row,
                    scope: this,
                    handler: this.onItemMenuCClick
                });
            }
            this.menuc = Ext.create('Ext.menu.Menu', {
                cls: "x-ux-PanelReportingCzasSelect-menu",
                items: mitems
            });
            this.buttondatamenu = this.menuc.query("[ftype=buttondatamenu]");
        }
    },
    changeViewCompact: function (compactView) {
        if (compactView === null) {
            compactView = this.compactView;
        }
        this.compactView = compactView;
        Ext.suspendLayouts();
        if (compactView) {
            if (this.menuc)
                this.menuc.destroy();
            this.menuc = null;
            this.createMenuC();
            this.buttonlsmenu.setMenu(this.menuc);
            this.buttonlsmenu.setHidden(false);
            this.buttonls.setHidden(true);
        } else {
            if (this.menuc)
                this.menuc.destroy();
            this.menuc = null;
            this.buttonlsmenu.setMenu(null);
            this.buttonlsmenu.setHidden(true);
            this.buttonls.setHidden(false);
        }
        this.updateLayout();
        Ext.resumeLayouts(true);
    },
    onItemMenuCClick: function (item, e, opts) {
        Ext.suspendLayouts();
        var v = item.value;
        this.value = v;
        this.buttonls.setValue(v);
        this.setupValue();
        this.updateStateButtonMenuState();
        Ext.resumeLayouts(true);
        this.fireEvent("selectrowevery", this);
        this.fireEvent("selectrow", this);
    },
    onButtonToogle: function (c, b, s) {
        this.setupValue();
        this.updateStateButtonMenuState();
        this.fireEvent("selectrowevery", this);
        this.fireEvent("selectrow", this);
    },
    updateStateButtonMenuState: function () {
        var dv = "...";
        var idx = this.store.findExact(this.valueField, this.value);
        if (idx >= 0) {
            var row = this.store.getAt(idx);
            dv = row.get(this.displayField);
        }
        this.buttonlsmenu.setText(dv);
    },
    createDefaultRowModel: function () {
        var r = {};
        if (this.value) {
            if (this.valueField) {
                r[this.valueField] = this.value;
            }
        }
        if (this.displayField !== this.valueField) {
            r[this.displayField] = Ext.util.Format.htmlEncode(this.valueNotFoundExd);
        }
        var rown = new this.store.model(r);
        return rown;
    },
    setupValue: function () {
        this.value = this.buttonls.getValue();
    },
    bindValue: function () {
        if (this.value != null) {
            var rowsSelected = [];
            var rowIndex = this.store.findExact(this.valueField, this.value);
            if (rowIndex >= 0) {
                r = this.store.getAt(rowIndex);
                rowsSelected.push(r);
                if (this.addedDefaultRow) {
                    this.addedDefaultRow = false;
                    this.createButtons();
                }
            } else {
                if (this.controlEmptyValue) {
                    this.addedDefaultRow = true;
                    var rown = this.createDefaultRowModel();
                    this.store.suspendEvents();
                    this.store.add(rown);
                    this.store.resumeEvents();
                    rowsSelected.push(rown);
                    this.createButtons();
                }
            }
            if (rowsSelected.length > 0) {
                this.buttonls.setValue(rowsSelected[0].get(this.valueField));
            } else {
                this.buttonls.setValue(null);
            }
        } else {
            this.buttonls.setValue(null);
        }
        this.updateStateButtonMenuState();
    },
    findRecord: function (field, value) {
        var ds = this.store,
            idx = ds.findExact(field, value);
        return idx !== -1 ? ds.getAt(idx) : false;
    },
    getValue: function () {
        return this.value;
    },
    setValue: function (value) {
        this.value = value;
        if (this.store.isLoaded()) {
            this.bindValue();
        }
    },
    getValue2: function () {
        return this.getValue();
    },
    setValue2: function (value) {
        return this.setValue(value);
    },
    getValue2NL: function () {
        return this.getValue();
    },
    setValue2NL: function (value) {
        return this.setValue(value);
    },
    setValue2IfExist: function (value) {
        var row = this.findRecord(this.valueField, value);
        if (row !== false) {
            this.setValue(value);
            return true;
        }
        return false;
    },
    getValue2Default: function () {
        var row = this.findRecord(this.valueField, this.getValue());
        if (row === false) {
            if (this.store.getCount() > 0) {
                row = this.store.getAt(0);
                this.setValue(row.get(this.valueField));
            } else
                this.setValue(null);
        }
        return this.getValue();
    },
    setValue2Default: function (value) {
        var row = this.findRecord(this.valueField, value);
        if (row !== false) {
            this.setValue(value);
        } else {
            if (this.store.getCount() > 0) {
                row = this.store.getAt(0);
                this.setValue(row.get(this.valueField));
            } else
                this.setValue(null);
        }
    },
    getRawValue: function () {
        var row = this.findRecord(this.valueField, this.value);
        if (row !== false) {
            return row.get(this.displayField);
        } else {
            return null;
        }
    },
    getRawValue2: function () {
        return this.getRawValue();
    },
    getRawValue2NL: function () {
        return this.getRawValue();
    }
});
