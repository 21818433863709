Ext.define('Ext.ux.form.field.MonthExd', {
    extend: 'Ext.form.field.Date',
    alias: 'widget.monthfieldexd',
    selectMonth: null,
    format: 'F, Y',
    clickOnSelect: true,
    createPicker: function () {
        var me = this,
            format = Ext.String.format;
        return Ext.create('Ext.picker.Month', {
            pickerField: me,
            ownerCt: me.ownerCt,
            floating: true,
            focusable: false,
            hidden: true,
            minDate: me.minValue,
            maxDate: me.maxValue,
            disabledDatesRE: me.disabledDatesRE,
            disabledDatesText: me.disabledDatesText,
            disabledDays: me.disabledDays,
            disabledDaysText: me.disabledDaysText,
            format: me.format,
            showToday: me.showToday,
            startDay: me.startDay,
            minText: format(me.minText, me.formatDate(me.minValue)),
            maxText: format(me.maxText, me.formatDate(me.maxValue)),
            keyNavConfig: {
                esc: function () {
                    me.collapse();
                }
            },
            listeners: {
                afterrender: {
                    scope: me,
                    fn: function (c) {
                        var me = c;
                        me.el.on("mousedown", function (e) {
                            e.preventDefault();
                        }, c);
                    }
                },
                select: {
                    scope: me,
                    fn: me.onSelect
                },
                monthdblclick: {
                    scope: me,
                    fn: me.onOKClick
                },
                yeardblclick: {
                    scope: me,
                    fn: me.onOKClick
                },
                okclick: {
                    scope: me,
                    fn: me.onOKClick
                },
                cancelclick: {
                    scope: me,
                    fn: me.onCancelClick
                }
            }
        });
    },
    onCancelClick: function () {
        var me = this;
        me.selectMonth = null;
        me.collapse();
    },
    onOKClick: function () {
        var me = this;
        if (me.selectMonth) {
            me.setValue(me.selectMonth);
            me.fireEvent('select', me, me.selectMonth);
        }
        me.collapse();
    },
    onSelect: function (m, d) {
        var me = this;
        me.selectMonth = new Date((d[0] + 1) + '/1/' + d[1]);
        if (this.clickOnSelect) {
            this.onOKClick();
        }
    },
    getValue2: function () {
        if (this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "")) {
            return null;
        }
        return Ext.Date.format(this.getValue(), "Y-m-d H:i:s");
    },
    getValue2String: function () {
        if (this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "")) {
            return null;
        }
        return (this.getValue().trim ? this.getValue().trim() : this.getValue());
    },
    getValue2Default: function () {
        if (this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "")) {
            this.setValue(new Date());
            return Ext.Date.format(this.getValue(), "Y-m-d H:i:s");
        }
        return Ext.Date.format(this.getValue(), "Y-m-d H:i:s");
    },
    getValue2Default2: function () {
        if (this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "")) {
            this.setValue(new Date());
            return this.getValue();
        }
        return (this.getValue().trim ? this.getValue().trim() : this.getValue());
    },
    setValue2: function (v, vd) {
        if (vd != null) {
            v = v || vd;
        }
        if (!Ext.isDate(v) && v != null) {
            v = Ext.Date.parseDate(v, "Y-m-d H:i:s");
        }
        this.setValue(v === null ? "" : v);
    },
    setValue2String: function (v) {
        this.setValue(v === null ? "" : v);
    },
    setValue2Default: function (v) {
        this.setValue(v === null ? "" : v);
    },
    setValue2Default2: function (v) {
        this.setValue(v === null ? "" : v);
    },
    getValue2M: function () {
        if (this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "")) {
            return null;
        }
        return Ext.Date.format(this.getValue(), "Y-m");
    },
    setValue2M: function (v, vd) {
        if (vd != null) {
            v = v || vd;
        }
        if (!Ext.isDate(v) && v != null) {
            v = Ext.Date.parseDate(v, "Y-m");
        }
        this.setValue(v === null ? "" : v);
    }
});
