Ext.define('Ext.ux.StateHTTPProviderExd', {
    extend: 'Ext.state.Provider',
    writeParamsBufferInterval: 1000,
    constructor: function (config) {
        Ext.ux.StateHTTPProviderExd.superclass.constructor.call(this);
        this.reMap = {};
        this.toName = null;
        this.callback = null;
        this.scope = null;
        this.paramsReadOp = null;
        this.paramsRead = null;
        this.paramsWriteOp = null;
        this.paramsWrite = null;
        Ext.apply(this, config);
        this.writeParams = Ext.Function.createBuffered(this.writeParams, this.writeParamsBufferInterval, this);
        if (this.useInitState) {
            this.readInitState(this.initState);
        } else {
            this.readParams();
        }
    },
    set: function (name, value) {
        if (this.reMap.name)
            this.reMap.name.set(this.reMap.name.toName ? this.reMap.name.toName : name, value);
        else {
            Ext.ux.StateHTTPProviderExd.superclass.set.call(this, name, value);
            this.writeParams();
        }
    },
    set2: function (name, value) {
        if (this.reMap.name)
            this.reMap.name.set(this.reMap.name.toName ? this.reMap.name.toName : name, value);
        else {
            Ext.ux.StateHTTPProviderExd.superclass.set.call(this, name, value);
        }
    },
    get: function (name) {
        if (this.reMap.name)
            return this.reMap.name.get(this.reMap.name.toName ? this.reMap.name.toName : name);
        else {
            return Ext.ux.StateHTTPProviderExd.superclass.get.call(this, name);
        }
    },
    clear: function (name) {
        if (this.reMap.name)
            this.reMap.name.clear(this.reMap.name.toName ? this.reMap.name.toName : name);
        else {
            Ext.ux.StateHTTPProviderExd.superclass.clear.call(this, name);
            this.writeParams();
        }
    },
    clear2: function (name) {
        if (this.reMap.name)
            this.reMap.name.clear(this.reMap.name.toName ? this.reMap.name.toName : name);
        else {
            Ext.ux.StateHTTPProviderExd.superclass.clear.call(this, name);
        }
    },
    readInitState: function (initState) {
        try {
            if (Ext.isString(initState)) {
                var state = Ext.decode(initState);
                this.state = ((state == null || !Ext.isObject(state)) ? {} : state);
            } else {
                this.state = ((initState == null || !Ext.isObject(initState)) ? {} : initState);
            }
        } catch (exception) {
            this.state = {};
        }
    },
    sendAjaxDataJson: function (url, params, scope, callbackFn, callbackError, alertErrors) {
        scope = scope || window;
        url = url + "?rnd=" + Math.random();
        Ext.Ajax.request({
            url: url,
            params: mapNullValuesEncodeOrDeleteAndMergeData(params),
            callback: function (opts, success, response) {
                if (success) {
                    if (callbackFn != null) {
                        var data = null;
                        try {
                            data = Ext.decode(response.responseText);
                        } catch (exception) {
                            success = false;
                            if (alertErrors)
                                alert(exception + ":" + response.responseText);
                        }
                        if (!data.success)
                            success = false;
                        if (!data.success && callbackError)
                            callbackError.call(scope, data, success, response.responseText);
                        else
                            callbackFn.call(scope, data, success, response.responseText);
                    }
                } else {
                    if (callbackError != null)
                        callbackError.call(scope, null, success, null);
                    else if (callbackFn != null)
                        callbackFn.call(scope, null, success, null);
                }
            }
        });
    },
    readParams: function () {
        try {
            var params = {};
            Ext.apply(params, this.paramsRead);
            this.sendAjaxDataJson(this.paramsReadOp, params, this, function (data) {
                try {
                    var state = Ext.decode(data);
                    this.state = ((state == null || !Ext.isObject(state)) ? {} : state);
                } catch (exception) {
                    this.state = {};
                }
                if (this.callback != null)
                    this.callback.call(this.scope);
            });
        } catch (exception) {
        }
    },
    writeParams: function () {
        try {
            var params = {};
            Ext.apply(params, this.paramsWrite);
            params.data = Ext.encode(this.state);
            this.sendAjaxDataJson(this.paramsWriteOp, params);
        } catch (exception) {
        }
    }
});
