if (Ext.data && Ext.data.Types) {
    Ext.data.Types.stripRe = /[\$,%]/g;
}

if (Ext.Date) {
    Ext.Date.monthNames = ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"];

    Ext.Date.getShortMonthName = function (month) {
        return Ext.Date.monthNames[month].substring(0, 3);
    };

    Ext.Date.monthNumbers = {
        "Styczeń": 0,
        Sty: 0,
        "Luty": 1,
        Lut: 1,
        "Marzec": 2,
        Mar: 2,
        "Kwiecień": 3,
        Kwi: 3,
        "Maj": 4,
        Maj: 4,
        "Czerwiec": 5,
        Cze: 5,
        "Lipiec": 6,
        Lip: 6,
        "Sierpień": 7,
        Sie: 7,
        "Wrzesień": 8,
        Wrz: 8,
        "Październik": 9,
        Paź: 9,
        "Listopad": 10,
        Lis: 10,
        "Grudzień": 11,
        Gru: 11
    };

    Ext.Date.getMonthNumber = function (name) {
        return Ext.Date.monthNumbers[name.substring(0, 1).toUpperCase() + name.substring(1, 3).toLowerCase()];
    };

    Ext.Date.dayNames = ["Niedziela", "Poniedziałek", "Wtorek", "środa", "Czwartek", "Piątek", "Sobota"];

    Ext.Date.getShortDayName = function (day) {
        switch (day) {
            case 0:
                return 'ndz';
            case 1:
                return 'pon';
            case 2:
                return 'wt';
            case 3:
                return 'śr';
            case 4:
                return 'czw';
            case 5:
                return 'pt';
            case 6:
                return 'sob';
            default:
                return '';
        }
    };

    Ext.Date.parseCodes.S.s = "(?:st|nd|rd|th)";

    Ext.Date.firstDayOfWeek = 1;
    Ext.Date.weekendDays = [6, 0];

    Ext.Date.defaultFormat = "Y-m-d H:i:s";
}

if (Ext.util.Format) {
    Ext.apply(Ext.util.Format, {
        thousandSeparator: ' ',
        decimalSeparator: ',',
        currencySign: '\u007a\u0142',
        dateFormat: "Y-m-d H:i:s",
        defaultDateFormat: "Y-m-d H:i:s"
    });
}

if (Ext.data.validator.Bound) {
    Ext.data.validator.Bound.prototype.emptyMessage = "Must be present";
}

if (Ext.data.validator.Email) {
    Ext.data.validator.Email.prototype.message = "Is not a valid email address";
}

if (Ext.data.validator.Exclusion) {
    Ext.data.validator.Exclusion.prototype.message = "Is a value that has been excluded";
}

if (Ext.data.validator.Format) {
    Ext.data.validator.Format.prototype.message = "Is in the wrong format";
}

if (Ext.data.validator.Inclusion) {
    Ext.data.validator.Inclusion.prototype.message = "Is not in the list of acceptable values";
}

if (Ext.data.validator.Length) {
    Ext.data.validator.Length.prototype.minOnlyMessage = "Length must be at least {0}";
    Ext.data.validator.Length.prototype.maxOnlyMessage = "Length must be no more than {0}";
    Ext.data.validator.Length.prototype.bothMessage = "Length must be between {0} and {1}";
}

if (Ext.data.validator.Presence) {
    Ext.data.validator.Presence.prototype.message = "Must be present";
}

if (Ext.data.validator.Range) {
    Ext.data.validator.Range.prototype.minOnlyMessage = "Must be must be at least {0}";
    Ext.data.validator.Range.prototype.maxOnlyMessage = "Must be no more than than {0}";
    Ext.data.validator.Range.prototype.bothMessage = "Must be between {0} and {1}";
    Ext.data.validator.Range.prototype.nanMessage = "Must be numeric";
}

if (Ext.view.View) {
    Ext.view.View.prototype.emptyText = "";
}

if (Ext.grid.plugin.DragDrop) {
    Ext.grid.plugin.DragDrop.prototype.dragText = "{0} wybrano wiersze(y)";
}

if (Ext.LoadMask) {
    Ext.LoadMask.prototype.msg = "Przetwarzanie danych...";
}

if (Ext.view.AbstractView) {
    Ext.view.AbstractView.prototype.loadingText = "Wczytywanie danych...";
}

if (Ext.picker.Date) {
    Ext.apply(Ext.picker.Date.prototype, {
        todayText: "Dzisiaj",
        minText: "Data jest wcześniejsza od daty minimalnej",
        maxText: "Data jest późniejsza od daty maksymalnej",
        disabledDaysText: "",
        disabledDatesText: "",
        monthNames: Ext.Date.monthNames,
        dayNames: Ext.Date.dayNames,
        nextText: "Następny miesiąc (Control+StrzałkaWPrawo)",
        prevText: "Poprzedni miesiąc (Control+StrzałkaWLewo)",
        monthYearText: "Wybierz miesiąc (Control+Up/Down aby zmienić rok)",
        todayTip: "{0} (Spacja)",
        format: "Y-m-d H:i:s",
        startDay: 1
    });
}

if (Ext.picker.Month) {
    Ext.apply(Ext.picker.Month.prototype, {
        okText: "&#160;OK&#160;",
        cancelText: "Anuluj"
    });
}

if (Ext.PagingToolbar) {
    Ext.apply(Ext.PagingToolbar.prototype, {
        beforePageText: "Strona",
        afterPageText: "z {0}",
        firstText: "Pierwsza strona",
        prevText: "Poprzednia strona",
        nextText: "Następna strona",
        lastText: "Ostatnia strona",
        refreshText: "Odśwież",
        displayMsg: "Wyświetlono {0} - {1} z {2}",
        emptyMsg: "Brak danych do wyświetlenia"
    });
}

if (Ext.picker.Month) {
    Ext.apply(Ext.picker.Month.prototype, {
        okText: "&#160;OK&#160;",
        cancelText: "Anuluj"
    });
}

if (Ext.form.field.Basic) {
    Ext.form.field.Basic.prototype.waitTitle = "Proszę czekać...";
}

if (Ext.form.field.Base) {
    Ext.form.field.Base.prototype.invalidText = "Wartość tego pola jest niewłaściwa";
}

if (Ext.form.field.Text) {
    Ext.apply(Ext.form.field.Text.prototype, {
        minLengthText: "Minimalna ilość znaków dla tego pola to {0}",
        maxLengthText: "Maksymalna ilość znaków dla tego pola to {0}",
        blankText: "To pole jest wymagane",
        regexText: "",
        emptyText: null
    });
}

if (Ext.form.field.Number) {
    Ext.apply(Ext.form.field.Number.prototype, {
        decimalSeparator: ",",
        decimalPrecision: 2,
        minText: "Minimalna wartość dla tego pola to {0}",
        maxText: "Maksymalna wartość dla tego pola to {0}",
        nanText: "{0} to nie jest właściwa wartość"
    });
}

if (Ext.form.field.Date) {
    Ext.apply(Ext.form.field.Date.prototype, {
        disabledDaysText: "Wyłączony",
        disabledDatesText: "Wyłączony",
        minText: "Data w tym polu musi być późniejsza od {0}",
        maxText: "Data w tym polu musi być wcześniejsza od {0}",
        invalidText: "{0} to nie jest prawidłowa data - prawidłowy format daty {1}",
        format: "Y-m-d H:i:s",
        altFormats: "m/d/Y|m-d-y|m-d-Y|m/d|m-d|md|mdy|mdY|d|Y-m-d|Y-m-d H:i:s",
        startDay: 1,
        formatText: "Oczekiwany format daty {0}"
    });
}

if (Ext.form.field.ComboBox) {
    Ext.apply(Ext.form.field.ComboBox.prototype, {
        valueNotFoundText: undefined
    });
    Ext.apply(Ext.form.field.ComboBox.prototype.defaultListConfig, {
        loadingText: "Wczytuję..."
    });
}

if (Ext.form.field.VTypes) {
    Ext.apply(Ext.form.field.VTypes.prototype, {
        emailText: 'To pole wymaga podania adresu e-mail w formacie: "nazwa@domena.pl"',
        urlText: 'To pole wymaga podania adresu strony www w formacie: "http:/' + '/www.domena.pl"',
        alphaText: 'To pole wymaga podania tylko liter i _',
        alphanumText: 'To pole wymaga podania tylko liter, cyfr i _'
    });
}

if (Ext.form.field.HtmlEditor) {
    Ext.apply(Ext.form.field.HtmlEditor.prototype, {
        createLinkText: 'Wprowadź adres URL strony:',
        buttonTips: {
            bold: {
                title: 'Pogrubienie (Ctrl+B)',
                text: 'Ustaw styl zaznaczonego tekstu na pogrubiony.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            italic: {
                title: 'Kursywa (Ctrl+I)',
                text: 'Ustaw styl zaznaczonego tekstu na kursywę.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            underline: {
                title: 'Podkreślenie (Ctrl+U)',
                text: 'Podkreśl zaznaczony tekst.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            increasefontsize: {
                title: 'Zwiększ czcionkę',
                text: 'Zwiększ rozmiar czcionki.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            decreasefontsize: {
                title: 'Zmniejsz czcionkę',
                text: 'Zmniejsz rozmiar czcionki.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            backcolor: {
                title: 'Wyróżnienie',
                text: 'Zmień kolor wyróżnienia zaznaczonego tekstu.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            forecolor: {
                title: 'Kolor czcionki',
                text: 'Zmień kolor zaznaczonego tekstu.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            justifyleft: {
                title: 'Do lewej',
                text: 'Wyrównaj tekst do lewej.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            justifycenter: {
                title: 'Wyśrodkuj',
                text: 'Wyrównaj tekst do środka.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            justifyright: {
                title: 'Do prawej',
                text: 'Wyrównaj tekst do prawej.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            insertunorderedlist: {
                title: 'Lista wypunktowana',
                text: 'Rozpocznij listę wypunktowaną.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            insertorderedlist: {
                title: 'Lista numerowana',
                text: 'Rozpocznij listę numerowaną.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            createlink: {
                title: 'Hiperłącze',
                text: 'Przekształć zaznaczony tekst w hiperłącze.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            },
            sourceedit: {
                title: 'Edycja źródła',
                text: 'Przełącz w tryb edycji źródła.',
                cls: Ext.baseCSSPrefix + 'html-editor-tip'
            }
        }
    });
}

if (Ext.grid.header.Container) {
    Ext.apply(Ext.grid.header.Container.prototype, {
        sortAscText: "Sortuj rosnąco",
        sortDescText: "Sortuj malejąco",
        columnsText: "Kolumny"
    });
}

if (Ext.grid.feature.Grouping) {
    Ext.apply(Ext.grid.feature.Grouping.prototype, {
        emptyGroupText: '(None)',
        groupByText: 'Grupuj po tym polu',
        showGroupsText: 'Pokaż w grupach',
        collapseTip: 'Klikaj aby zwinąć. Klikaj razem z naciśniętym CTRL aby zwinąć wszystkich grup'
    });
}

if (Ext.grid.PropertyColumnModel) {
    Ext.apply(Ext.grid.PropertyColumnModel.prototype, {
        nameText: "Nazwa",
        valueText: "Wartość",
        dateFormat: "Y-m-d H:i:s",
        trueText: "tak",
        falseText: "nie"
    });
}

if (Ext.grid.BooleanColumn) {
    Ext.apply(Ext.grid.BooleanColumn.prototype, {
        trueText: "tak",
        falseText: "nie",
        undefinedText: '&#160;'
    });
}

if (Ext.grid.NumberColumn) {
    Ext.apply(Ext.grid.NumberColumn.prototype, {
        format: '0.00'
    });
}

if (Ext.grid.DateColumn) {
    Ext.apply(Ext.grid.DateColumn.prototype, {
        format: "Y-m-d H:i:s"
    });
}

if (Ext.form.field.Time) {
    Ext.apply(Ext.form.field.Time.prototype, {
        minText: "Wprowadzona wartość musi być większa lub równa {0}",
        maxText: "Wprowadzona wartość musi być mniejsza lub równa {0}",
        invalidText: "{0} nie jest poprawną wartością",
        format: "H:i:s",
        altFormats: "g:ia|g:iA|g:i a|g:i A|h:i|g:i|H:i|ga|ha|gA|h a|g a|g A|gi|hi|gia|hia|g|H|H:i:s"
    });
}

if (Ext.form.field.File) {
    Ext.apply(Ext.form.field.File.prototype, {
        buttonText: "Wybierz..."
    });
}

if (Ext.form.CheckboxGroup) {
    Ext.apply(Ext.form.CheckboxGroup.prototype, {
        blankText: "Musisz wybrać przynajmniej jedną wartość w grupie"
    });
}

if (Ext.form.RadioGroup) {
    Ext.apply(Ext.form.RadioGroup.prototype, {
        blankText: "Musisz wybrać przynajmniej jedną wartość w grupie"
    });
}

if (Ext.window.MessageBox) {
    Ext.apply(Ext.window.MessageBox.prototype, {
        buttonText: {
            ok: "OK",
            cancel: "Anuluj",
            yes: "Tak",
            no: "Nie"
        }
    });
}

if (Ext.grid.filters.Filters) {
    Ext.grid.filters.Filters.prototype.menuFilterText = "Filtrowanie";
}

if (Ext.grid.filters.filter.Boolean) {
    Ext.apply(Ext.grid.filters.filter.Boolean.prototype, {
        yesText: "Tak",
        noText: "Nie"
    });
}

if (Ext.grid.filters.filter.Date) {
    Ext.grid.filters.filter.Date.prototype.dateFormat = "Y-m-d H:i:s";
    Ext.override(Ext.grid.filters.filter.Date, {
        config: {
            fields: {
                lt: {
                    text: "Przed"
                },
                gt: {
                    text: "Po"
                },
                eq: {
                    text: "w"
                }
            }
        }
    });
}

if (Ext.grid.filters.filter.List) {
    Ext.apply(Ext.grid.filters.filter.List.prototype, {
        loadingText: "Ładowanie..."
    });
}

if (Ext.grid.filters.filter.Number) {
    Ext.apply(Ext.grid.filters.filter.Number.prototype, {
        emptyText: "Wprowadź wartość..."
    });
}

if (Ext.grid.filters.filter.String) {
    Ext.apply(Ext.grid.filters.filter.String.prototype, {
        emptyText: 'Wprowadź wartość...'
    });
}

if (Ext.view.MultiSelectorSearch) {
    Ext.apply(Ext.view.MultiSelectorSearch.prototype, {
        searchText: 'Wyszukiwanie...'
    });
}

if (Ext.view.MultiSelector) {
    Ext.apply(Ext.view.MultiSelector.prototype, {
        emptyText: 'Nic nie wybrano',
        removeRowTip: 'Usuń element',
        addToolText: 'Dodaj element'
    });
}

if (Ext.data.field.Date) {
    Ext.data.field.Date.prototype.dateFormat = "Y-m-d H:i:s";
    Ext.data.field.Date.prototype.dateReadFormat = "Y-m-d H:i:s";
    Ext.data.field.Date.prototype.dateWriteFormat = "Y-m-d H:i:s";
}

if (Ext.Updater) {
    Ext.Updater.defaults.indicatorText = '<div class="loading-indicator">Wczytywanie danych...</div>';
}

if (Ext.panel.Panel) {
    Ext.apply(Ext.panel.Panel.prototype, {
        collapseToolText: "Zwiń",
        expandToolText: "Rozwiń"
    });
}

if (Ext.tab.Tab) {
    Ext.tab.Tab.prototype.closeText = "Zamknij zakładkę";
}

if (Ext.window.Window) {
    Ext.apply(Ext.window.Window.prototype, {
        closeToolText: "Zamknij"
    });
}

Ext.DECIMAL_SEPARATOR = ",";
