Ext.define('Ext.ux.window.MessageBoxMobileExd', {
    extend: 'Ext.window.MessageBox',
    alias: 'widget.messageboxmobileexd',
    initComponent: function () {
        this.callParent(arguments);
        try {
            this.maxWidth = Ext.getBody().getViewSize().width;
        } catch (err) {
            console.warn(err);
        }
    }
});