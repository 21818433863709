Ext.define('WindowHelp', {
    extend: 'Ext.ux.window.WindowExd',
    width: 700,
    height: 700,
    frame: false,
    border: false,
    header: false,
    closable: false,
    plain: true,
    scrollable: false,
    style: "background-color: transparent; border-style: none;",
    shadow: false,
    collapsible: false,
    modal: true,
    resizable: false,
    constrain: false,
    constrainHeader: false,
    layout: 'fit',
    src: 'example.com',
    initComponent: function () {
        this.cid = Ext.id();
        this.fzitems = [{
            itemId: 'company-form',
            xtype: 'form',
            layout: 'anchor',
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
                labelWidth: 150,
                labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
            },
            items: [{
                itemId: 'nazwa',
                xtype: 'textfieldexd',
                fieldLabel: Lang.firma_zarzadzajaca,
                editable: false
            }, {
                itemId: 'telefon',
                xtype: 'textfieldexd',
                fieldLabel: Lang.telefon,
                editable: false
            }, {
                itemId: 'email',
                xtype: 'textfieldexd',
                fieldLabel: Lang.email,
                editable: false
            }, {
                anchor: "0",
                fieldLabel: Lang.opis,
                isFormField: true,
                itemId: "opis",
                name: "opis",
                xtype: 'textareaexd',
                editable: false
            }]
        }];
        this.items = [{
            xtype: 'panel',
            items: [{
                xtype: 'panel',
                bodyStyle: 'padding:20px 20px 20px 20px',
                height: 320,
                width: 640,
                items: this.fzitems
            }, {
                xtype: 'box',
                autoEl: {
                    tag: 'iframe',
                    src: this.src,
                    width: 700,
                    height: 320
                }
            }, {
                xtype: 'panel',
                items: [Ext.create('Ext.panel.Panel', {
                    id: this.cid + 'btnpanel',
                    style: 'margin-top: 18px;',
                    cls: 'about-btnpanel',
                    layout: {
                        type: 'hbox',
                        align: 'stretch',
                        pack: 'center'
                    },
                    items: [{
                        xtype: 'button',
                        id: this.cid + "okb",
                        text: Lang.ok.capitalise(),
                        height: 20,
                        width: 65,
                        listeners: {
                            scope: this,
                            click: function () {
                                this.closeOp();
                            }
                        }
                    }]
                })]
            }]
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        this.center();
        this.fillFields();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
    },
    fillFields: function () {
        performAjaxLPromise('rest/firmy/getInfoAboutFZForCurrentFirm', {}).then(data => {
            this.obiekt = data;
            this.down('#nazwa').setValue(this.obiekt.nazwa);
            this.down('#telefon').setValue(this.obiekt.telefon);
            this.down('#email').setValue(this.obiekt.email);
            this.down('#opis').setValue(this.obiekt.opis);
        });
    },
    closeOp: function () {
        this.close();
    }
});
