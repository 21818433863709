Ext.define('Ext.grid.filters.filter.DateOverride', {
    override: 'Ext.grid.filters.filter.Date',
    onStateRestore: function (filter) {
        var me = this;
        var value = filter.getValue();
        if (value) {
            var newValue = Ext.Date.parse(value, me.getDateFormat());
            if (!newValue) {
                newValue = Ext.Date.parse(value, "C");
            }
            if (newValue) {
                filter.setValue(newValue);
            }
        }
        filter.setSerializer(this.getSerializer());
        filter.setConvert(this.convertDateOnly);
    }
    /*
    getSerializer : function() {
        var me = this;
        return function(data) {
            var value = data.value;
            if (value) {
                data.value = Ext.Date.format(value, me.getDateFormat());
                if (!data.value) {
                    var oldValue = value;
                    value = Ext.Date.parse(oldValue, "C");
                    if (value) {
                        data.value = Ext.Date.format(value, me.getDateFormat());
                    }
                }
            }
        };
    }
    */
});
