Ext.define('Ext.ux.form.RadioGroupExd', {
    extend: 'Ext.form.RadioGroup',
    alias: 'widget.radiogroupexd',
    simpleValue: true,
    initComponent: function () {
        Ext.Array.each(this.items, function (item) {
            item.name = this.name || this.id;
        }, this);
        this.callParent(arguments);
        if (this.value != null) {
            this.setValue2(this.value);
        }
    },
    setValue2: function (v) {
        if (this.simpleValue) {
            this.setValue.apply(this, [v]);
        } else {
            var vo = v;
            var vv = {};
            vv[this.name || this.id] = v;
            this.setValue.apply(this, [vv]);
        }
    },
    getValue2: function () {
        if (this.simpleValue) {
            var v = this.getValue();
            return v;
        } else {
            var v = this.getValue();
            var out = null;
            if (v[this.name || this.id] != undefined) {
                out = v[this.name || this.id];
            }
            return out;
        }
    }
});
