Ext.define('Ext.ux.form.field.CheckboxExd', {
    extend: 'Ext.form.field.Checkbox',
    alias: 'widget.checkboxexd',
    initComponent: function () {
        if (this.value !== undefined) {
            this.checked = this.value;
        }
        this.callParent(arguments);
    },
    onRender: function () {
        Ext.ux.form.field.CheckboxExd.superclass.onRender.apply(this, arguments);
    },
    getValue2: function () {
        return this.getValue();
    },
    setValue2: function (v) {
        this.setValue(v);
    }
});
