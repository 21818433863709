window.ExtjsMobileBrowserWindowEvents = new Ext.util.Observable({});
Ext.onReady(function () {
    window.onresize = () => ExtjsMobileBrowserWindowEvents.fireEvent('windowResized');
});

window.WindowExdMobileDEVICEV = false;
Ext.define('Ext.ux.window.WindowExd', {
    extend: 'Ext.window.Window',
    alias: 'widget.windowexd',
    scrollable: true,
    autoAdjustWidth: true,
    autoAdjustHeight: true,
    autoAdjustPosition: true,
    autoCenterAfterRender: false,
    isMobileDevice: undefined,
    initComponent: function () {
        this.callParent(arguments);
        if (this.isMobileDevice == undefined) {
            this.isMobileDevice = WindowExdMobileDEVICEV;
        }
        if (this.isMobileDevice) {
            this.setWidth(window.innerWidth);
            this.setHeight(window.innerHeight);
            this.mon(ExtjsMobileBrowserWindowEvents, 'windowResized', this.fitWindowHeight, this);
        }
    },
    show: function () {
        this.callParent(arguments);
        if (!this.isMobileDevice) {
            Ext.on('resize', this.onWindowResizeExd, this, {
                buffer: 1
            });
        }
    },
    hide: function () {
        this.callParent(arguments);
        if (!this.isMobileDevice) {
            Ext.un('resize', this.onWindowResizeExd, this);
        }
    },
    close: function () {
        this.callParent(arguments);
        if (!this.isMobileDevice) {
            Ext.un('resize', this.onWindowResizeExd, this);
        }
    },
    onWindowResizeExd: function () {
        this.checkSizeC();
        if ((this.getX() !== null) && (this.getY() !== null)) {
            this.checkPosC(this.getX(), this.getY());
        }
    },
    onWindowMoveExd: function (x, y) {
        this.checkPosC(x, y);
    },
    checkPosC: function (x, y) {
        var c = this;
        if (c.autoAdjustPosition) {
            var maxX = Ext.dom.Element.getViewportWidth();
            var maxY = Ext.dom.Element.getViewportHeight();
            x = parseInt(x);
            y = parseInt(y);
            var mw = c.getWidth();
            var mh = c.getHeight();
            if (x < 0 || x > maxX || y < 0 || y > maxY) {
                if (x > maxX)
                    x = maxX - mw;
                if (y > maxY)
                    y = maxY - mh;
                if (x < 0)
                    x = 0;
                if (y < 0)
                    y = 0;
                if (x != c.getX() || y != c.getY()) {
                    this.ignoreMoveEvents = true;
                    c.setPosition(x, y);
                }
            }
            if ((x + mw > maxX) || (y + mh > maxY)) {
                if ((x + mw > maxX)) {
                    x = maxX - mw;
                }
                if ((y + mh > maxY)) {
                    y = maxY - mh;
                }
                if (x < 0)
                    x = 0;
                if (y < 0)
                    y = 0;
                if (x != c.getX() || y != c.getY()) {
                    this.ignoreMoveEvents = true;
                    c.setPosition(x, y);
                }
            }
        }
    },
    checkSizeC: function () {
        var c = this;
        if (c.autoAdjustWidth) {
            var cwm = Ext.dom.Element.getViewportWidth();
            if (c.iwidth) {
                var nv = Math.min(c.iwidth, cwm);
                if (c.getWidth() != nv)
                    c.setWidth(nv);
            }
            if (c.getWidth() > cwm) {
                if (!c.iwidth) {
                    c.iwidth = c.getWidth();
                }
                c.setWidth(cwm);
            }
        }
        if (c.autoAdjustHeight) {
            var chm = Ext.dom.Element.getViewportHeight();
            if (c.iheight) {
                var nv = Math.min(c.iheight, chm);
                if (c.getHeight() != nv)
                    c.setHeight(nv);
            }
            if (c.getHeight() > chm) {
                if (!c.iheight) {
                    c.iheight = c.getHeight();
                }
                c.setHeight(chm);
            }
        }
        if (c.autoCenterAfterRender) {
            c.center();
        }
    },
    fitWindowHeight: function () {
        this.setWidth(window.innerWidth);
        this.setHeight(window.innerHeight);
    },
    listeners: {
        move: function (c, x, y) {
            if (this.ignoreMoveEvents) {
                this.ignoreMoveEvents = false;
                return;
            }
            this.onWindowMoveExd(x, y);
        },
        resize: function (c) {
            if (!this.isMobileDevice) {
                this.onWindowResizeExd();
            }
        },
        afterrender: function (c) {
            if (!this.isMobileDevice) {
                this.onWindowResizeExd();
            }
        }
    }
});
