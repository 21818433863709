Ext.define('Ext.ux.form.field.TextAreaExd', {
    extend: 'Ext.form.field.TextArea',
    alias: 'widget.textareaexd',
    getValue2: function () {
        return this.getValue() === null || this.getValue() === "" || (this.getValue() != null && this.getValue().trim && this.getValue().trim() === "") ? null : (this.getValue().trim ? this.getValue().trim() : this.getValue());
    },
    setValue2: function (v) {
        this.setValue(v === null ? "" : v);
    }
});
