Ext.define('Ext.ux.form.field.FileExd', {
    extend: 'Ext.form.field.Text',
    alias: ['widget.filefieldexd', 'widget.fileuploadfieldexd'],
    requires: ['Ext.form.field.FileButton', 'Ext.form.trigger.Component'],
    emptyText: undefined,
    needArrowKeys: false,
    triggers: {
        filebuttonexd: {
            type: 'component',
            hideOnReadOnly: false,
            preventMouseDown: false
        }
    },
    buttonText: 'Browse...',
    buttonOnly: false,
    buttonMargin: 3,
    clearOnSubmit: true,
    extraFieldBodyCls: Ext.baseCSSPrefix + 'form-file-wrap',
    inputCls: Ext.baseCSSPrefix + 'form-text-file',
    readOnly: true,
    editable: false,
    submitValue: false,
    triggerNoEditCls: '',
    childEls: ['browseButtonWrap'],
    multiple: false,
    text_multiple: "Selected files: {0}, {1}",
    // dla aplikacji php po nazwie pola musi być [], czyli na przykład name:"file[]"
    applyTriggers: function (triggers) {
        var me = this,
            triggerCfg = (triggers || {}).filebuttonexd;
        if (triggerCfg) {
            triggerCfg.component = Ext.apply({
                xtype: 'filebuttonexd',
                ownerCt: me,
                id: me.id + '-button',
                ui: me.ui,
                disabled: me.disabled,
                tabIndex: me.tabIndex,
                text: me.buttonText,
                style: me.buttonOnly ? '' : me.getButtonMarginProp() + me.buttonMargin + 'px',
                accept: me.accept,
                inputName: me.getName(),
                multiple: me.multiple,
                listeners: {
                    scope: me,
                    change: me.onFileChange
                }
            }, me.buttonConfig);
            return me.callParent([triggers]);
        } else {
            Ext.raise(me.$className + ' requires a valid trigger config containing "filebuttonexd" specification');
        }
    },
    getSubTplData: function (fieldData) {
        var data = this.callParent([fieldData]);
        data.tabIdx = -1;
        return data;
    },
    onRender: function () {
        var me = this,
            inputEl,
            button,
            buttonEl,
            trigger;
        me.callParent(arguments);
        inputEl = me.inputEl;
        inputEl.dom.name = '';
        inputEl.on('focus', me.onInputFocus, me);
        inputEl.on('mousedown', me.onInputMouseDown, me);
        trigger = me.getTrigger('filebuttonexd');
        button = me.button = trigger.component;
        me.fileInputEl = button.fileInputEl;
        buttonEl = button.el;
        if (me.buttonOnly) {
            me.inputWrap.setDisplayed(false);
            me.shrinkWrap = 3;
        }
        trigger.el.setWidth(buttonEl.getWidth() + buttonEl.getMargin('lr'));
        if (Ext.isIE) {
            me.button.getEl().repaint();
        }
    },
    getTriggerMarkup: function () {
        return '<td id="' + this.id + '-browseButtonWrap" data-ref="browseButtonWrap" role="presentation"></td>';
    },
    onFileChange: function (button, e, value) {
        var sv = value;
        var filels = button.fileInputEl.getAttribute("files");
        if (filels) {
            var totallength = 0;
            for (var i = 0; i < filels.length; i++) {
                var name = filels[i].name;
                var size = filels[i].size;
                var type = filels[i].type;
                totallength += size;
            }
            totallength = Ext.util.Format.fileSize(totallength);
            if (filels.length > 1) {
                sv = Ext.util.Format.format(this.text_multiple, filels.length, totallength);
            }
        }
        this.duringFileSelect = true;
        Ext.form.field.File.superclass.setValue.call(this, sv);
        delete this.duringFileSelect;
    },
    didValueChange: function () {
        return !!this.duringFileSelect;
    },
    setEmptyText: Ext.emptyFn,
    setValue: Ext.emptyFn,
    reset: function () {
        var me = this,
            clear = me.clearOnSubmit;
        if (me.rendered) {
            me.button.reset(clear);
            me.fileInputEl = me.button.fileInputEl;
            if (clear) {
                me.inputEl.dom.value = '';
                Ext.form.field.File.superclass.setValue.call(this, null);
            }
        }
        me.callParent();
    },
    onShow: function () {
        this.callParent();
        this.button.updateLayout();
    },
    onDisable: function () {
        this.callParent();
        this.button.disable();
    },
    onEnable: function () {
        this.callParent();
        this.button.enable();
    },
    isFileUpload: Ext.returnTrue,
    extractFileInput: function () {
        var me = this,
            fileInput;
        if (me.rendered) {
            fileInput = me.button.fileInputEl.dom;
            me.reset();
        } else {
            fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.className = Ext.baseCSSPrefix + 'hidden-display';
            fileInput.name = me.getName();
        }
        return fileInput;
    },
    restoreInput: function (el) {
        if (this.rendered) {
            var button = this.button;
            button.restoreInput(el);
            this.fileInputEl = button.fileInputEl;
        }
    },
    onDestroy: function () {
        this.fileInputEl = this.button = null;
        this.callParent();
    },
    getButtonMarginProp: function () {
        return this.getInherited().rtl ? 'margin-right:' : 'margin-left:';
    },
    onInputFocus: function (e) {
        var me = this;
        if (me.selectOnFocus && document.activeElement === me.inputEl.dom) {
            me.inputEl.dom.select();
        }
        me.focus();
        if (Ext.isIE9m) {
            me.fileInputEl.addCls(Ext.baseCSSPrefix + 'position-relative');
            me.fileInputEl.removeCls(Ext.baseCSSPrefix + 'position-relative');
        }
    },
    onInputMouseDown: function (e) {
        e.preventDefault();
        this.focus();
    },
    privates: {
        getFocusEl: function () {
            return this.button;
        },
        getFocusClsEl: Ext.privateFn
    }
});

