Ext.define('Ext.ux.ComponentExd', {
    extend: 'Ext.Component',
    alias: 'widget.boxexd',
    initComponent: function () {
        this.callParent(arguments);
    },
    getElExd: function () {
        var me = this;
        var el = me.touchScroll ? me.getScrollerEl() : me.getTargetEl();
        return el;
    },
    updateLayoutExd: function () {
        var me = this;
        var scroller = me.getScrollable();
        var container = me.focusableContainer;
        this.updateLayout();
        if (scroller) {
            scroller.refresh(true);
        }
        if (container) {
            container.onFocusableChildUpdate(me);
        }
    }
});
