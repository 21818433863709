window.normalizeViewFiledErrorMessageLabel = (m) => {
    if (m != null && m != "") {
        var lastSign = m.substring(m.length - 1, m.length);
        if (lastSign == "!" || lastSign == "?" || lastSign == ".") {
            return m.substring(0, m.length - 1);
        }
    }
    return m;
};

window.removeObjectFromArrayById = (cols, id) => {
    var cfi = -1;
    for (var i = 0; i < cols.length; i++) {
        if (cols[i].dataIndex == id) {
            cfi = i;
            break;
        }
    }
    if (cfi > -1) {
        cols.splice(cfi, 1);
    }
    return cols;
};

window.removeObjectFromArrayById2 = function (cols, id) {
    var cfi = -1;
    for (var i = 0; i < cols.length; i++) {
        if (cols[i].id == id) {
            cfi = i;
            break;
        }
    }
    if (cfi > -1) {
        cols.splice(cfi, 1);
    }
    return cols;
};

window.printStackTraceExd = (e) => {
    if (!console) {
        var console = {};
        console.log = function (msg) {
        };
        console.info = function (msg) {
        };
        console.warn = function (msg) {
        };
    }
    var msg = e.name + ":" + e.message;
    if (e.fileName) {
        msg += " at " + e.fileName + ":" + e.lineNumber;
    }
    if (console) {
        console.log(msg);
        if (e.stack) {
            console.log(e.stack);
        }
    }
}

window.customLocaleSort = (record1, record2, columnName) => {
    var name1 = record1.data[columnName],
        name2 = record2.data[columnName];
    return name1.localeCompare(name2);
}

/*sorter : {
 *	propertyName: 'imie',
 *	sorterFn: customSorterForgrid,
 *}, - do definicji kolumny w gridzie
 */
 window.customSorterForGrid = function (record1, record2, columnName) {
    if (!columnName) {
        columnName = this.config.propertyName;
    }
    var value1 = record1.get(columnName);
    var value2 = record2.get(columnName);
    if (typeof value1 == 'string' && typeof value2 == 'string') {
        return value1.localeCompare(value2);
    } else {
        if (value1 == value2)
            return 0;
        if (value1 == null && value2 != null)
            return 1;
        if (value2 == null && value1 != null)
            return -1;
        if (value1 < value2)
            return -1;
        if (value1 > value2)
            return 1;
    }
};

window.printSortedMessages = function (msgss) {
    var t1 = [];
    for (var e in msgss) {
        t1.push(e);
    }
    t1.sort();
    var msgss2 = {};
    for (var i = 0; i < t1.length; i++) {
        msgss2[t1[i]] = msgss[t1[i]];
    }
    Ext.getBody().update(Ext.encode(msgss2));
};

window.printSortedMessages2 = function (msgss) {
    var ss = "";
    for (var e in msgss) {
        ss += msgss[e] + "<br>";
    }
    Ext.getBody().update(ss);
};

window.putEmptyIframe = (url) => {
    Ext.getBody().insertHtml("beforeEnd", '<iframe src="' + url + '" style="display:none"></iframe>', true);
}

window.postFormAsync = (url, data, target_blank) => {
    data = mapNullValuesEncodeOrDelete(data);
    var adds = "";
    if (target_blank)
        adds += 'target="_blank"';
    var el = Ext.getBody().insertHtml("beforeEnd", '<form action="' + url + '" method="post" ' + adds + ' style="display:none"></form>', true);
    for (var k in data) {
        hd = document.createElement('input');
        hd.type = 'hidden';
        hd.name = k;
        hd.value = typeof data[k] == 'object' ? Ext.encode(data[k]) : data[k];
        el.appendChild(hd);
    }
    el.dom.submit();
    setTimeout(function () {
        el.remove();
    }, 100);
}

window.renderBool = function (value, metaData, record, rowIndex, colIndex, store, view) {
    var vcls = value ? 'x-checkboxexd-checked-render' : 'x-checkboxexd-unchecked-render';
    return '<div class="x-checkboxexd-grid-col-render">' + '<div class="' + vcls + '"' + '>' + '</div>' + '</div>';
};

window.renderWrap = function (value, metaData, record, rowIndex, colIndex, store, view) {
    if (value == null)
        return value;
    return '<div class="x-gridexd-col-wrap">' + value + '</div>';
};

window.renderQtip = function (value, metaData, record, rowIndex, colIndex, store, view) {
    metaData.attr = 'data-qtip="' + value + '"';
    return value;
};

window.renderWrapQtip = function (value, metaData, record, rowIndex, colIndex, store, view) {
    if (value == null)
        return value;
    metaData.attr = 'data-qtip="' + value + '"';
    return '<div ' + 'data-qtip="' + value + '"' + ' class="x-gridexd-col-wrap">' + value + '</div>';
};

window.renderWrapQtipDateRenderer = function (format) {
    var ff = function (value, metaData, record, rowIndex, colIndex, store, view, ffdr) {
        if (value == null)
            return value;
        value = ffdr.call(window, value, metaData, record, rowIndex, colIndex, store, view);
        metaData.attr = 'data-qtip="' + value + '"';
        return '<div ' + 'data-qtip="' + value + '"' + ' class="x-gridexd-col-wrap">' + value + '</div>';
    };
    var fdr = Ext.util.Format.dateRenderer(format);
    return Ext.bind(ff, window, [fdr], true);
};

window.renderWrapQtipCustomRenderer = function (fdr) {
    var ff = function (value, metaData, record, rowIndex, colIndex, store, view, ffdr) {
        if (value == null)
            return value;
        value = ffdr.call(window, value, metaData, record, rowIndex, colIndex, store, view);
        metaData.attr = 'data-qtip="' + value + '"';
        return '<div ' + 'data-qtip="' + value + '"' + ' class="x-gridexd-col-wrap">' + value + '</div>';
    };
    if (!fdr) {
        fdr = function (value, metaData, record, rowIndex, colIndex, store, view) {
            return value;
        };
    }
    return Ext.bind(ff, window, [fdr], true);
};

window.renderWrapQtipCustomTplRenderer = function (tpl) {
    var tpl = (!Ext.isPrimitive(tpl) && tpl.compile) ? tpl : new Ext.XTemplate(tpl);
    var ff = function (value, metaData, record, rowIndex, colIndex, store, view, tpl) {
        var data = Ext.apply({}, record.data, record.getAssociatedData());
        value = tpl.apply(data);
        metaData.attr = 'data-qtip="' + value + '"';
        return '<div ' + 'data-qtip="' + value + '"' + ' class="x-gridexd-col-wrap">' + value + '</div>';
    };
    return Ext.bind(ff, window, [tpl], true);
};

window.normalizeViewFilledErrorMessageLabel = function (m) {
    if (m != null && m != "") {
        var lastSign = m.substring(m.length - 1, m.length);
        if (lastSign == "!" || lastSign == "?" || lastSign == ".") {
            return m.substring(0, m.length - 1);
        }
    }
    return m;
};

window.HSVToRGBObj = (hsv) => {
    return HSVToRGB(hsv.h, hsv.s, hsv.v);
}

window.RGBToHSVObj = (rgb) => {
    return RGBToHSV(rgb.r, rgb.g, rgb.b);
}

window.HSVToRGB = (hue, sat, val) => {
    // hue 0-359, sat i val od 0 do 1, r,g,b od 0 do 1
    if (val == 0) {
        red = 0;
        grn = 0;
        val = 0;
    } else {
        hue /= 60;
        i = Math.floor(hue);
        f = hue - i;
        p = val * (1 - sat);
        q = val * (1 - (sat * f));
        t = val * (1 - (sat * (1 - f)));
        if (i == 0) {
            red = val;
            grn = t;
            blu = p;
        } else if (i == 1) {
            red = q;
            grn = val;
            blu = p;
        } else if (i == 2) {
            red = p;
            grn = val;
            blu = t;
        } else if (i == 3) {
            red = p;
            grn = q;
            blu = val;
        } else if (i == 4) {
            red = t;
            grn = p;
            blu = val;
        } else if (i == 5) {
            red = val;
            grn = p;
            blu = q;
        }
    }
    return {
        r: parseInt(red * 255),
        g: parseInt(grn * 255),
        b: parseInt(blu * 255)
    };
}

window.RGBToHSV = (red, grn, blu) => {
    // hue 0-359, sat i val od 0 do 1, r,g,b od 0 do 1
    red = parseFloat(red) / 255;
    grn = parseFloat(grn) / 255;
    blu = parseFloat(blu) / 255;
    x = Math.min(Math.min(red, grn), blu);
    val = Math.max(Math.max(red, grn), blu);
    if (x == val) {
        hue = 0;
        sat = 0;
    } else {
        if (red == x)
            f = grn - blu;
        else if (grn == x)
            f = blu - red;
        else
            f = red - grn;
        if (red == x)
            i = 3;
        else if (grn == x)
            i = 5;
        else
            i = 1;
        hue = ((i - f / (val - x)) * 60) % 360;
        sat = ((val - x) / val);
    }
    return {
        h: hue,
        s: sat,
        v: val
    };
}

window.RGBtoHex = (R, G, B) => {
    return toHex(R) + toHex(G) + toHex(B);
}
