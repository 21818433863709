Ext.define("App.WebSocketFileIO", {
    extend: "Ext.window.Window",
    title: "File manager",
    width: 400,
    layout: {
        type: "vbox",
        align: "stretch"
    },
    czytnik: null,
    dataR: null,
    mode: null,
    modal: true,
    callbackW: null,
    scopeW: null,
    zawartoscToWrite: null,
    initComponent: function () {
        this.cid = this.id;
        this.items = [this.createPanel()];
        this.buttons = [{
            xtype: "button",
            text: "Save",
            align: "center",
            scope: this,
            handler: this.saveButtonHandler
        }];
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.callParent(arguments);
    },
    readFileContent: function (callbackW, scopeW) {
        this.mode = "read";
        this.callbackW = callbackW;
        this.scopeW = scopeW;
        this.show();
    },
    writeFileContent: function (zawartoscToWrite, callbackW, scopeW) {
        this.mode = "write";
        this.zawartoscToWrite = zawartoscToWrite.replace(/[\s]/g, "");
        var file = this.base64ToBlob(this.zawartoscToWrite, "octet/stream");
        var d = new Date();
        var filename = "smdp_" + d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + "_" + d.getHours() + "-" + d.getMinutes() + "-" + d.getSeconds() + ".bin";
        if (window.navigator.msSaveOrOpenBlob)// IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else {// Others
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
        this.dataR = true;
        callbackW.call(scopeW, this, this.dataR);
    },
    close: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.callParent(arguments);
    },
    createPanel: function () {
        var panel = {
            xtype: 'panel',
            layout: "fit",
            items: [{
                xtype: "filefieldexd",
                itemId: "filechooser",
                listeners: {
                    scope: this,
                    change: this.file2Base64
                }
            }]
        };
        return panel;
    },
    saveButtonHandler: function () {
        if (this.dataR !== null) {
            this.callbackW.call(this.scopeW, this, this.dataR);
            this.close();
        }
    },
    file2Base64: function (fieldExd) {
        var file = fieldExd.button.fileInputEl.getAttribute("files")[0];
        var expectedFileSize = 8 * 1024;
        // 8KB
        if (expectedFileSize !== file.size) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        var reader = new FileReader();

        reader.onload = Ext.bind(function (e) {
            this.dataR = arrayBufferToBase64(reader.result);
        }, this);

        reader.readAsArrayBuffer(file);

        function arrayBufferToBase64(buffer) {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        }

    },
    base64ToBlob: function (b64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, {
            type: contentType
        });
        return blob;
    }
});
