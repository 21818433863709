Ext.define('Ext.ux.form.field.FileButtonExd', {
    extend: 'Ext.button.Button',
    alias: 'widget.filebuttonexd',
    childEls: ['fileInputEl'],
    inputCls: Ext.baseCSSPrefix + 'form-file-input',
    cls: Ext.baseCSSPrefix + 'form-file-btn',
    preventDefault: false,
    tabIndex: undefined,
    useTabGuards: Ext.isIE || Ext.isEdge,
    promptCalled: false,
    autoEl: {
        tag: 'div',
        unselectable: 'on'
    },
    afterTpl: ['<input id="{id}-fileInputEl" data-ref="fileInputEl" class="{childElCls} {inputCls}" ', 'type="file" size="1" name="{inputName}" unselectable="on" ', '<tpl if="accept != null">accept="{accept}"</tpl>', '<tpl if="tabIndex != null">tabindex="{tabIndex}"</tpl>', '>'],
    keyMap: null,
    ariaEl: 'fileInputEl',
    multiple: false,
    getAfterMarkup: function (values) {
        return this.lookupTpl('afterTpl').apply(values);
    },
    getTemplateArgs: function () {
        var me = this,
            args;
        args = me.callParent();
        args.inputCls = me.inputCls;
        args.inputName = me.inputName || me.id;
        args.tabIndex = me.tabIndex != null ? me.tabIndex : null;
        args.accept = me.accept || null;
        args.role = me.ariaRole;
        return args;
    },
    afterRender: function () {
        var me = this,
            listeners,
            cfg;
        me.callParent(arguments);
        if (me.multiple) {
            me.fileInputEl.set({
                multiple: "multiple"
            });
        }
        listeners = {
            scope: me,
            mousedown: me.handlePrompt,
            keydown: me.handlePrompt,
            change: me.fireChange,
            focus: me.onFileFocus,
            blur: me.onFileBlur
        };
        if (me.useTabGuards) {
            cfg = {
                tag: 'span',
                role: 'button',
                'aria-hidden': 'true',
                'data-tabguard': 'true',
                style: {
                    height: 0,
                    width: 0
                }
            };
            cfg.tabIndex = me.tabIndex != null ? me.tabIndex : 0;
            me.beforeInputGuard = me.el.createChild(cfg, me.fileInputEl);
            me.afterInputGuard = me.el.createChild(cfg);
            me.afterInputGuard.insertAfter(me.fileInputEl);
            me.beforeInputGuard.on('focus', me.onInputGuardFocus, me);
            me.afterInputGuard.on('focus', me.onInputGuardFocus, me);
            listeners.keydown = me.onFileInputKeydown;
        }
        me.fileInputEl.on(listeners);
    },
    fireChange: function (e) {
        this.fireEvent('change', this, e, this.fileInputEl.dom.value);
    },
    createFileInput: function (isTemporary) {
        var me = this,
            fileInputEl,
            listeners;
        fileInputEl = me.fileInputEl = me.el.createChild({
            name: me.inputName || me.id,
            id: !isTemporary ? me.id + '-fileInputEl' : undefined,
            cls: me.inputCls + (me.getInherited().rtl ? ' ' + Ext.baseCSSPrefix + 'rtl' : ''),
            tag: 'input',
            type: 'file',
            size: 1,
            unselectable: 'on',
            multiple: me.multiple ? "multiple" : undefined
        }, me.afterInputGuard);
        fileInputEl.dom.setAttribute('data-componentid', me.id);
        if (me.tabIndex != null) {
            me.setTabIndex(me.tabIndex);
        }
        if (me.accept) {
            fileInputEl.dom.setAttribute('accept', me.accept);
        }
        listeners = {
            scope: me,
            change: me.fireChange,
            mousedown: me.handlePrompt,
            keydown: me.handlePrompt,
            focus: me.onFileFocus,
            blur: me.onFileBlur
        };
        if (me.useTabGuards) {
            listeners.keydown = me.onFileInputKeydown;
        }
        fileInputEl.on(listeners);
    },
    handlePrompt: function (e) {
        var key;
        if (e.type == 'keydown') {
            key = e.getKey();
            this.promptCalled = ((!Ext.isIE && key === e.ENTER) || key === e.SPACE) ? true : false;
        } else {
            this.promptCalled = true;
        }
    },
    onFileFocus: function (e) {
        var ownerCt = this.ownerCt;
        if (!this.hasFocus) {
            this.onFocus(e);
        }
        if (ownerCt && !ownerCt.hasFocus) {
            ownerCt.onFocus(e);
        }
    },
    onFileBlur: function (e) {
        var ownerCt = this.ownerCt;
        if (this.promptCalled) {
            this.promptCalled = false;
            e.preventDefault();
            return;
        }
        if (this.hasFocus) {
            this.onBlur(e);
        }
        if (ownerCt && ownerCt.hasFocus) {
            ownerCt.onBlur(e);
        }
    },
    onInputGuardFocus: function (e) {
        this.fileInputEl.focus();
    },
    onFileInputKeydown: function (e) {
        var key = e.getKey(),
            focusTo;
        if (key === e.TAB) {
            focusTo = e.shiftKey ? this.beforeInputGuard : this.afterInputGuard;
            if (focusTo) {
                focusTo.suspendEvent('focus');
                focusTo.focus();
                setTimeout(function () {
                    focusTo.resumeEvent('focus');
                }, 0);
            }
        } else if (key === e.ENTER || key === e.SPACE) {
            this.handlePrompt(e);
        }
        return true;
    },
    reset: function (remove) {
        var me = this;
        if (remove) {
            me.fileInputEl.destroy();
        }
        me.createFileInput(!remove);
        if (remove) {
            me.ariaEl = me.fileInputEl;
        }
    },
    restoreInput: function (el) {
        var me = this;
        me.fileInputEl.destroy();
        el = Ext.get(el);
        if (me.useTabGuards) {
            el.insertBefore(me.afterInputGuard);
        } else {
            me.el.appendChild(el);
        }
        me.fileInputEl = el;
    },
    onDisable: function () {
        this.callParent();
        this.fileInputEl.dom.disabled = true;
    },
    onEnable: function () {
        this.callParent();
        this.fileInputEl.dom.disabled = false;
    },
    privates: {
        getFocusEl: function () {
            return this.fileInputEl;
        },
        getFocusClsEl: function () {
            return this.el;
        },
        setTabIndex: function (tabIndex) {
            var me = this;
            if (!me.focusable) {
                return;
            }
            me.tabIndex = tabIndex;
            if (!me.rendered || me.destroying || me.destroyed) {
                return;
            }
            if (me.useTabGuards) {
                me.fileInputEl.dom.setAttribute('tabIndex', -1);
                me.beforeInputGuard.dom.setAttribute('tabIndex', tabIndex);
                me.afterInputGuard.dom.setAttribute('tabIndex', tabIndex);
            } else {
                me.fileInputEl.dom.setAttribute('tabIndex', tabIndex);
            }
        }
    }
});
