Ext.define('Ext.ux.form.field.DisplayExd', {
    extend: 'Ext.form.field.Display',
    alias: 'widget.displayexd',
    baseCls: "x-ux-displayexd",
    textTrue: "Tak",
    textFalse: "Nie",
    tooltipConfig: {
        listeners: {
            'beforeshow': function (c) {
                c.update(this.ownerct.value);
            }
        }
    },
    initComponent: function () {
        this.callParent(arguments);
    },
    afterRender: function () {
        this.callParent(arguments);
        this.tooltipConfig.target = this.getEl();
        this.tooltipConfig.ownerct = this;
        this.tooltipel = Ext.create('Ext.tip.ToolTip', this.tooltipConfig);
    },
    onDestroy: function () {
        if (this.tooltipel)
            this.tooltipel.destroy();
        this.callParent(arguments);
    },
    setValueT: function (value, langmap) {
        if (Ext.isBoolean(value)) {
            this.setValue(value ? this.textTrue : this.textFalse);
        } else if (Ext.isNumber(value)) {
            this.setValue(value);
        } else if (Ext.isNumeric(value)) {
            this.setValue(value);
        } else if (Ext.isString(value)) {
            this.setValue(value);
        } else if (langmap) {
            var svalue = langmap[value];
            this.setValue(svalue === null ? value : svalue);
        } else {
            this.setValue(value);
        }
    },
    getValue2: function () {
        return this.getValue();
    },
    setValue2: function (v) {
        this.setValue(v);
    }
});
