if (Ext.ux.form.field.FileExd) {
    Ext.apply(Ext.ux.form.field.FileExd.prototype, {
        text_multiple: "Wybranych plików: {0}, {1}",
        buttonText: "Przeglądaj" + '...'
    });
}

if (Ext.ux.form.field.DisplayExd) {
    Ext.apply(Ext.ux.form.field.DisplayExd.prototype, {
        textTrue: "Tak",
        textFalse: "Nie"
    });
}

if (Ext.ux.form.field.FieldLabelExd) {
    Ext.apply(Ext.ux.form.field.FieldLabelExd.prototype, {
        textTrue: "Tak",
        textFalse: "Nie"
    });
}

if (Ext.ux.form.field.LabelExd) {
    Ext.apply(Ext.ux.form.field.LabelExd.prototype, {
        textTrue: "Tak",
        textFalse: "Nie"
    });
}
