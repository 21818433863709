Ext.define('Ext.ux.view.ViewComboExd', {
    extend: 'Ext.view.View',
    alias: 'widget.viewcomboexd',
    nodeContainerSelector: ".x-ux-viewcomboexd-container",
    itemSelector: ".x-ux-viewcomboexd-item-c",
    itemSelectorc: ".x-ux-viewcomboexd-item-c",
    actionSelector: ".x-ux-viewcomboexd-item-action",
    actionDeferSelector: ".x-ux-viewcomboexd-item-action-defer",
    deleteSelector: ".x-ux-viewcomboexd-item-delete",
    editSelector: ".x-ux-viewcomboexd-item-edit",
    overItemCls: 'x-item-over',
    baseCls: "x-ux-viewcomboexd",
    displayField: 'text',
    valueField: 'value',
    autoSortStore: true,
    autoForceToSelect: false,
    value: null,
    controlEmptyValue: true,
    editable: false,
    editableDeleteBySelf: false,
    valueNotFoundExd: "<unknown>",
    longTouchEventHand: false,
    disableEditActionByOver: true,
    selectionModel: {
        mode: "SINGLE",
        allowDeselect: false,
        ignoreRightMouseSelection: true,
        deselectOnContainerClick: false
    },
    initComponent: function () {
        if (this.editable) {
            this.editor = Ext.create("Ext.ux.view.ViewComboExdEditor", {
                dataIndex: this.displayField
            });
            this.editor.init(this);
            this.addCls("x-ux-editable");
        }
        if (!this.tpl) {
            var actionTpls = ['<div class="x-ux-viewcomboexd-item-action x-ux-viewcomboexd-item-delete"></div>', '<div class="x-ux-viewcomboexd-item-action x-ux-viewcomboexd-item-edit"></div>'];
            actionTpls = actionTpls.join("");
            this.tpl = new Ext.XTemplate('<div class="x-ux-viewcomboexd-container">', '<tpl for=".">', '<div class="x-ux-viewcomboexd-item-c">', '<div class="x-ux-viewcomboexd-item"><span>', "{" + this.displayField + "}", '</span></div>', actionTpls, '</div>', '</tpl>', '</div>', {});
        }
        if (!this.store || this.store == "ext-empty-store") {
            var sorters = this.autoSortStore ? [{
                property: (typeof this.autoSortStore === "string") ? this.autoSortStore : this.displayField,
                direction: "ASC"
            }] : undefined;
            this.store = Ext.create('Ext.data.Store', {
                model: Ext.define(null, {
                    extend: 'Ext.data.Model',
                    idProperty: this.valueField,
                    fields: [this.displayField, this.valueField]
                }),
                proxy: {
                    type: 'memory',
                    reader: {
                        type: "json"
                    }
                },
                sorters: sorters
            });
        }
        if (this.datas && this.store) {
            this.store.loadData(this.datas);
        }
        this.callParent(arguments);
        this.selectionModel.on("beforeselect", this.beforeSelectChange, this);
        this.selectionModel.on("beforedeselect", this.beforeDeSelectChange, this);
        this.selectionModel.on("selectionchange", this.selectionChange, this);
        this.on("itemclick", this.itemClick, this);
        this.store.on("datachanged", this.storeLoad, this);
        if (this.autoForceToSelect) {
            if ((!this.store.isLoaded())) {
                this.store.on({
                    datachanged: {
                        fn: function () {
                            this.setValue2Default(this.getValue());
                        },
                        scope: this,
                        single: true
                    }
                });
            } else {
                this.setValue2Default(this.getValue());
            }
        } else {
            if (this.getValue()) {
                this.setValue2(this.getValue());
            }
        }
    },
    onDestroy: function () {
        this.callParent(arguments);
    },
    afterRender: function () {
        this.callParent(arguments);
        this.el.on("mouseover", this.onElMainCCMouseOver, this);
        this.el.on("mouseout", this.onElMainCCMouseOut, this);
        this.el.on("click", this.onElMainCCClick, this);
        this.el.on("swipe", this.onElMainCCComplexTA, this);
        this.el.on("longpress", this.onElMainCCComplexTA, this);
        if (this.disableEditActionByOver) {
            this.el.addCls("x-item-action-perm-only");
        }
    },
    onResize: function (width, height, oldWidth, oldHeight) {
        this.callParent(arguments);
    },
    onElMainCCMouseOver: function (e, t) {
        var el = e.getTarget(this.itemSelectorc, 10);
        el = Ext.get(el);
        if (el) {
            el.addCls("x-item-over");
        }
        var el = e.getTarget(this.actionSelector, 10);
        el = Ext.get(el);
        if (el) {
            el.addCls("x-item-over");
        }
    },
    onElMainCCMouseOut: function (e, t) {
        var el = e.getTarget(this.itemSelectorc, 10);
        el = Ext.get(el);
        if (el) {
            el.removeCls("x-item-over");
        }
        var el = e.getTarget(this.actionSelector, 10);
        el = Ext.get(el);
        if (el) {
            el.removeCls("x-item-over");
        }
    },
    onElMainCCClick: function (e, t) {
        var el = e.getTarget(this.actionSelector, 10);
        el = Ext.get(el);
        if (el) {
            if (el.hasCls(this.editSelector.replace(".", ""))) {
                var elc = el.up(this.itemSelectorc);
                var record = this.getRecord(elc.dom);
                this.startItemActionClick(record, "edit");
            }
            if (el.hasCls(this.deleteSelector.replace(".", ""))) {
                var elc = el.up(this.itemSelectorc);
                var record = this.getRecord(elc.dom);
                this.startItemActionClick(record, "delete");
            }
        }
    },
    onElMainCCComplexTA: function (e, t) {
        if (this.longTouchEventHand) {
            var el = e.getTarget(this.itemSelectorc, 10);
            el = Ext.get(el);
            if (el) {
                el.addCls("x-item-over");
                Ext.defer(function () {
                    el.removeCls("x-item-over");
                }, 5000, this);
            }
        }
    },
    itemClick: function (c, record, item, index, e) {
        if (this.disableClick) {
            this.disableClick = false;
            return;
        }
        var el = e.getTarget(this.actionSelector, 10);
        el = Ext.get(el);
        var el2 = e.getTarget(this.actionDeferSelector, 10);
        el2 = Ext.get(el2);
        if (!(el || el2)) {
            this.setupValue(record);
            this.fireEvent("selectrowevery", this);
        }
    },
    onItemClick: function (record, item, index, e) {
        this.callParent(arguments);
        var el = e.getTarget(this.actionSelector, 10);
        el = Ext.get(el);
        var el2 = e.getTarget(this.actionDeferSelector, 10);
        el2 = Ext.get(el2);
        if (el || el2 || this.permamentEditActive) {
            this.disableSelection = true;
            this.disableDeSelection = true;
            this.disableClick = true;
        }
    },
    beforeDeSelectChange: function (c, record, index, eopts) {
        if (this.disableDeSelection) {
            this.disableDeSelection = false;
            return false;
        }
    },
    beforeSelectChange: function (c, record, index, eopts) {
        if (this.disableSelection) {
            this.disableSelection = false;
            return false;
        }
    },
    selectionChange: function () {
        this.setupValue();
        if (!this.manualSetValue) {
            this.fireEvent("selectrow", this);
        }
    },
    startItemActionClick: function (record, action) {
        if (this.editable) {
            if (record !== false) {
                if (action == "edit") {
                    var item = this.getNode(record);
                    this.editor.onManualEdit(record, item);
                }
                if (action == "delete") {
                    var item = this.getNode(record);
                    if (this.editableDeleteBySelf) {
                        this.deleteDeferCanceled = false;
                        Ext.defer(function () {
                            this.fireEvent("deletedeferbegin", this, "deletedeferbegin", record, item);
                            var editfClick = function (e, t) {
                                if (this.deleteDeferCanceled) {
                                    Ext.getBody().un("click", editfClick, this);
                                    this.fireEvent("deletedefermanualend", this, "deletedefermanualend", record, item, e, t);
                                    this.fireEvent("deletedeferend", this, "deletedeferend", record, item);
                                    return;
                                }
                                var eitem = Ext.get(item);
                                var elc = e.getTarget("#" + eitem.getId(), 256);
                                if (elc == null) {
                                    Ext.getBody().un("click", editfClick, this);
                                    this.fireEvent("deletedefercancel", this, "deletedefercancel", record, item, e, t);
                                    this.fireEvent("deletedeferend", this, "deletedeferend", record, item);
                                }
                            };
                            Ext.getBody().on({
                                click: {
                                    fn: editfClick,
                                    scope: this
                                }
                            });
                        }, 10, this);
                    } else {
                        this.fireEvent("deleteperform", this, record, item);
                    }
                }
            }
        }
    },
    enterMenuActionPermState: function (s) {
        if (s) {
            this.permamentEditActive = true;
            this.el.addCls("x-item-action-perm");
        } else {
            this.permamentEditActive = false;
            this.el.removeCls("x-item-action-perm");
        }
    },
    endItemDeleteDeferManual: function () {
        this.deleteDeferCanceled = true;
    },
    startItemEditManual: function (value) {
        if (this.editable) {
            var record = this.findRecord(this.valueField, value);
            if (record !== false) {
                var item = this.getNode(record);
                this.editor.onManualEdit(record, item);
            }
        }
    },
    createDefaultRowModel: function () {
        var r = {};
        if (this.value) {
            if (this.valueField) {
                r[this.valueField] = this.value;
            }
        }
        if (this.displayField !== this.valueField) {
            r[this.displayField] = Ext.util.Format.htmlEncode(this.valueNotFoundExd);
        }
        var rown = new this.store.model(r);
        return rown;
    },
    storeLoad: function () {
        this.bindValue();
    },
    setupValue: function (record) {
        if (record) {
            this.value = record.get(this.valueField);
        } else {
            var rowsSelected = this.selectionModel.getSelection();
            if (rowsSelected.length > 0) {
                var row = rowsSelected[0];
                this.value = row.get(this.valueField);
            }
        }
    },
    bindValue: function () {
        if (this.value != null) {
            var rowsSelected = [];
            var rowIndex = this.store.findExact(this.valueField, this.value);
            if (rowIndex >= 0) {
                r = this.store.getAt(rowIndex);
                rowsSelected.push(r);
            } else {
                if (this.controlEmptyValue) {
                    var rown = this.createDefaultRowModel();
                    this.store.suspendEvents();
                    this.store.add(rown);
                    this.store.resumeEvents();
                    this.refresh();
                    rowsSelected.push(rown);
                }
            }
            var selectNewRows = true;
            var rowsSelectedAlready = this.selectionModel.getSelection();
            if (rowsSelectedAlready.length == rowsSelected.length) {
                selectNewRows = false;
                for (var i = 0; i < rowsSelectedAlready.length; i++) {
                    var rowExist = false;
                    for (var j = 0; j < rowsSelected.length; j++) {
                        if (rowsSelectedAlready[i].get(this.valueField) == rowsSelected[j].get(this.valueField)) {
                            rowExist = true;
                        }
                    }
                    if (!rowExist) {
                        selectNewRows = true;
                    }
                }
            }
            if (selectNewRows) {
                this.getSelectionModel().deselectAll(true);
                this.manualSetValue = true;
                this.getSelectionModel().select(rowsSelected, false);
                this.manualSetValue = false;
            }
        } else {
            this.getSelectionModel().deselectAll(true);
        }
    },
    findRecord: function (field, value) {
        var ds = this.store,
            idx = ds.findExact(field, value);
        return idx !== -1 ? ds.getAt(idx) : false;
    },
    getValue: function () {
        return this.value;
    },
    setValue: function (value) {
        this.value = value;
        if (this.store.isLoaded()) {
            this.bindValue();
        }
    },
    getValue2: function () {
        return this.getValue();
    },
    setValue2: function (value) {
        return this.setValue(value);
    },
    getValue2NL: function () {
        return this.getValue();
    },
    setValue2NL: function (value) {
        return this.setValue(value);
    },
    setValue2IfExist: function (value) {
        var row = this.findRecord(this.valueField, value);
        if (row !== false) {
            this.setValue(value);
            return true;
        }
        return false;
    },
    getDisplayValueD: function () {
        var row = this.findRecord(this.valueField, this.getValue());
        if (row !== false) {
            return row.get(this.displayField);
        }
        return "";
    },
    checkValue2Default: function () {
        var row = this.findRecord(this.valueField, this.getValue());
        if (row === false) {
            if (this.store.getCount() > 0) {
                row = this.store.getAt(0);
                this.setValue(row.get(this.valueField));
            } else
                this.setValue(null);
        }
    },
    getValue2Default: function () {
        var row = this.findRecord(this.valueField, this.getValue());
        if (row === false) {
            if (this.store.getCount() > 0) {
                row = this.store.getAt(0);
                this.setValue(row.get(this.valueField));
            } else
                this.setValue(null);
        }
        return this.getValue();
    },
    setValue2Default: function (value) {
        var row = this.findRecord(this.valueField, value);
        if (row !== false) {
            this.setValue(value);
        } else {
            if (this.store.getCount() > 0) {
                row = this.store.getAt(0);
                this.setValue(row.get(this.valueField));
            } else
                this.setValue(null);
        }
    },
    getRawValue: function () {
        var row = this.findRecord(this.valueField, this.value);
        if (row !== false) {
            return row.get(this.displayField);
        } else {
            return null;
        }
    },
    getRawValue2: function () {
        return this.getRawValue();
    },
    getRawValue2NL: function () {
        return this.getRawValue();
    }
});

Ext.define('Ext.ux.view.ViewComboExdEditor', {
    extend: 'Ext.Editor',
    alignment: 'tl-tl',
    completeOnEnter: true,
    cancelOnEsc: true,
    shim: false,
    autoSize: {
        width: 'boundEl',
        height: 'field'
    },
    labelSelector: 'x-editable',
    clickAutoEdit: false,
    requires: ['Ext.form.field.Text'],
    constructor: function (config) {
        config.field = config.field || Ext.create('Ext.form.field.Text', {
            allowOnlyWhitespace: false,
            selectOnFocus: true
        });
        this.callParent([config]);
    },
    init: function (view) {
        this.view = view;
        if (this.clickAutoEdit) {
            this.mon(view, 'afterrender', this.bindEvents, this);
        }
        this.view.on('containerclick', this.onContainerclick, this);
        this.on('complete', this.onSave, this);
    },
    bindEvents: function () {
        this.mon(this.view.getEl(), {
            click: {
                fn: this.onClick,
                scope: this
            }
        });
    },
    onClick: function (e, target) {
        var me = this,
            item,
            record;
        if (Ext.fly(target).hasCls(me.labelSelector) && !me.editing && !e.ctrlKey && !e.shiftKey) {
            e.stopEvent();
            item = me.view.findItemByChild(target);
            record = me.view.store.getAt(me.view.indexOf(item));
            me.startEdit(target, record.data[me.dataIndex]);
            me.activeRecord = record;
        } else if (me.editing) {
            me.field.blur();
            e.preventDefault();
        }
    },
    onManualEdit: function (record, item) {
        var me = this;
        if (!me.editing) {
            var target = Ext.get(item);
            me.startEdit(target, record.data[me.dataIndex]);
            me.activeRecord = record;
        } else if (me.editing) {
            me.field.blur();
        }
    },
    onContainerclick: function () {
        var me = this;
        if (me.editing) {
            me.field.blur();
        }
    },
    onSave: function (ed, value) {
        var oldvalue = this.activeRecord.get(this.dataIndex);
        this.activeRecord.set(this.dataIndex, value);
        if (this.view) {
            this.view.fireEvent("editcomplete", this.view, this, this.activeRecord, value, oldvalue);
        }
    }
});
