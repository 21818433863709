Ext.define('PanelReportingReportTypeSelect', {
    extend: 'Ext.container.Container',
    alias: 'widget.panelreportingreporttypeselect',
    cls: "a-PanelReportingReportTypeSelect",
    layout: {
        type: "hbox",
        align: "stretch"
    },
    store: null,
    valueField: "value",
    displayField: "text",
    value: null,
    initComponent: function () {
        this.cid = this.id;
        this.items = [{
            xtype: "tabpanel",
            itemId: "tabpanells",
            flex: 1,
            hidden: true,
            items: [],
            listeners: {
                scope: this,
                tabchange: this.onTabSelect
            }
        }, {
            xtype: "box",
            cls: "item-selector buttonmenuelem",
            html: '',
            hidden: true,
            itemId: "buttonmenuelem"
        }, {
            xtype: "box",
            cls: "item-selector displayvalueelem",
            hidden: true,
            html: {
                tag: "div",
                cls: "text-inner"
            },
            itemId: "displayvalueelem"
        }];
        this.store = Ext.create('Ext.data.Store', {
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: this.valueField,
                fields: [this.displayField, this.valueField]
            }),
            proxy: {
                type: 'memory',
                reader: {
                    type: "json"
                }
            }
        });
        this.callParent(arguments);
        this.calcWidthSelfAndReLayoutBuffered = Ext.Function.createBuffered(this.calcWidthSelfAndReLayout, 1, this);
        this.setValue(this.value);
    },
    afterRender: function () {
        this.callParent(arguments);
        this.el.on("click", this.onElMainCCClick, this);
        this.el.on("mouseover", this.onElMainCCMouseOver, this);
        this.el.on("mouseout", this.onElMainCCMouseOut, this);
        this.loadData();
    },
    onResize: function (width, height, oldWidth, oldHeight) {
        this.callParent(arguments);
        this.calcWidthSelfAndReLayoutBuffered();
    },
    onDestroy: function () {
        if (this.menu)
            this.menu.destroy();
        this.callParent(arguments);
    },
    onElMainCCMouseOver: function (e, t) {
        var el = e.getTarget(".item-selector", 10);
        el = Ext.get(el);
        if (el) {
            el.addCls("item-over");
        }
    },
    onElMainCCMouseOut: function (e, t) {
        var el = e.getTarget(".item-selector", 10);
        el = Ext.get(el);
        if (el) {
            el.removeCls("item-over");
        }
    },
    onElMainCCClick: function (e, t) {
        var el = e.getTarget(".item-selector", 10);
        el = Ext.get(el);
        if (el) {
            this.onItemClick(e, el);
        }
    },
    onItemClick: function (e, el) {
        if (el.hasCls("buttonmenuelem")) {
            this.createMenuC();
            this.menu.showBy(el);
        }
    },
    loadData: function () {
        var reporttypeLs = [{
            value: "daily",
            text: Lang.raport_dzienny
        }, {
            value: "weekday",
            text: Lang.raport_tygodniowy
        }, {
            value: "monthday",
            text: Lang.raport_miesieczny
        }, {
            value: "monthweek",
            text: Lang.raport_miesieczny_po_tygodniach
        }, {
            value: "yearmonth",
            text: Lang.raport_roczny
        }, {
            value: "yearweek",
            text: Lang.raport_roczny_po_tygodniach
        }];
        this.store.loadData(reporttypeLs);
        Ext.suspendLayouts();
        this.dataLoaded = true;
        this.createButtons();
        this.bindValue();
        this.calcWidthSelfAndReLayout();
        Ext.resumeLayouts(true);
    },
    createButtons: function () {
        var items = [];
        for (var i = 0; i < this.store.getCount(); i++) {
            var row = this.store.getAt(i);
            var v = row.get(this.valueField);
            var dv = row.get(this.displayField);
            var tabItem = {
                xtype: "panel",
                ftype: "tabitemdata",
                itemId: row.get(this.valueField),
                value: row.get(this.valueField),
                fvalue: row.get(this.valueField),
                title: row.get(this.displayField)
            };
            items.push(tabItem);
        }
        var tabpanells = this.down("#tabpanells");
        tabpanells.removeAll(true);
        tabpanells.add(items);
    },
    onTabSelect: function () {
        this.setupValue();
        if (!this.bindProccess) {
            this.updateRestStateV();
            this.fireEvent("selectrowevery", this);
            this.fireEvent("selectrow", this);
        }
    },
    setValue: function (value) {
        this.value = value;
        if (this.dataLoaded) {
            this.bindValue();
        }
    },
    getValue: function () {
        return this.value;
    },
    setupValue: function () {
        var tabpanells = this.down("#tabpanells");
        var tab = tabpanells.getActiveTab();
        this.value = tab.value;
    },
    bindValue: function () {
        this.bindProccess = true;
        var row = this.findRow(this.value);
        var dv = row.get(this.displayField);
        var v = row.get(this.valueField);
        var tabpanells = this.down("#tabpanells");
        tabpanells.setActiveTab(v);
        this.updateRestStateV();
        this.bindProccess = false;
    },
    findRow: function (v) {
        var row = null;
        var rowIndex = this.store.findExact(this.valueField, v);
        if (rowIndex >= 0) {
            row = this.store.getAt(rowIndex);
        } else {
            if (this.store.getCount() > 0) {
                row = this.store.getAt(0);
            }
        }
        return row;
    },
    updateRestStateV: function () {
        var row = this.findRow(this.value);
        var dv = row.get(this.displayField);
        var v = row.get(this.valueField);
        var displayvalueelem = this.down("#displayvalueelem");
        var buttonmenuelem = this.down("#buttonmenuelem");
        displayvalueelem.el.down(".text-inner").update(dv);
    },
    createMenuC: function () {
        if (!this.menu) {
            var mitems = [];
            for (var i = 0; i < this.store.getCount(); i++) {
                var row = this.store.getAt(i);
                var v = row.get(this.valueField);
                var dv = row.get(this.displayField);
                mitems.push({
                    text: dv,
                    vitem: v,
                    scope: this,
                    handler: this.onItemMenuClick
                });
            }
            this.menu = Ext.create('Ext.menu.Menu', {
                cls: "a-PanelReportingReportTypeSelect-menu",
                items: mitems
            });
        }
    },
    onItemMenuClick: function (item, e, opts) {
        this.value = item.vitem;
        if (!this.bindProccess) {
            this.updateRestStateV();
            this.fireEvent("selectrowevery", this);
            this.fireEvent("selectrow", this);
            this.bindProccess = true;
            var tabpanells = this.down("#tabpanells");
            tabpanells.setActiveTab(item.vitem);
            this.bindProccess = false;
        }
    },
    calcWidthSelfAndReLayout: function () {
        var viewAndSelectButton = false;
        var pikselPerEL = 92 / 14;
        var calcWidthTab = 0;
        for (var i = 0; i < this.store.getCount(); i++) {
            var row = this.store.getAt(i);
            var v = row.get(this.valueField);
            var dv = row.get(this.displayField);
            var itemlen = pikselPerEL * dv.length + 30;
            calcWidthTab += itemlen;
        }
        if (this.getWidth() < calcWidthTab) {
            viewAndSelectButton = true;
        }
        var tabpanells = this.down("#tabpanells");
        var displayvalueelem = this.down("#displayvalueelem");
        var buttonmenuelem = this.down("#buttonmenuelem");
        tabpanells.setHidden(viewAndSelectButton);
        displayvalueelem.setHidden(!viewAndSelectButton);
        buttonmenuelem.setHidden(!viewAndSelectButton);
        if (buttonmenuelem.isVisible() && Ext.os.deviceType == "Desktop")
            buttonmenuelem.addCls('buttonmenuelem-hover');
        this.updateLayout();
    }
});
