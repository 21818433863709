Ext.define('Ext.ux.ItemSelectorExd', {
    extend: 'Ext.form.FieldContainer',
    alias: 'widget.itemselectorexd',
    mixins: ['Ext.util.StoreHolder', 'Ext.form.field.Field'],
    layout: {
        type: 'hbox',
        align: 'stretch'
    },
    displayField: "text",
    valueField: "value",
    dragText: "&nbsp",
    delimiter: ",",
    allowBlank: true,
    selectAllOnNullValue: true,
    selectAllOnEmptyValue: true,
    returnEmptyOnAllSelected: true,
    initComponent: function () {
        var me = this;
        me.ddGroup = me.id + '-dd';
        me.items = me.setupItems();
        me.bindStore(me.store, true);
        me.callParent(arguments);
        me.initField();
    },
    setupItems: function () {
        var me = this;
        me.fromField = me.createList(me.fromTitle);
        me.toField = me.createList(me.toTitle);
        me.buttons = me.createButtons();
        var items = [me.fromField, me.buttons, me.toField];
        return items;
    },
    createButtons: function () {
        var me = this;
        var pb = Ext.create("Ext.panel.Panel", {
            layout: {
                type: "vbox",
                align: "stretch"
            },
            items: [{
                xtype: "panel",
                title: "&nbsp;"
            }, {
                flex: 1,
                xtype: "container",
                layout: {
                    type: "vbox",
                    align: "stretchmax",
                    pack: "center"
                },
                items: [{
                    xtype: "toolbar",
                    vertical: true,
                    items: [{
                        xtype: "button",
                        text: ">",
                        scope: this,
                        handler: this.onBtnSelectTo
                    }, {
                        xtype: "button",
                        text: ">>",
                        scope: this,
                        handler: this.onBtnSelectAll
                    }, {
                        xtype: "button",
                        text: "<",
                        scope: this,
                        handler: this.onBtnSelectFrom
                    }, {
                        xtype: "button",
                        text: "<<",
                        scope: this,
                        handler: this.onBtnClearAll
                    }]
                }]
            }]
        });
        return pb;
    },
    createList: function (title) {
        var me = this;
        return Ext.create('Ext.ux.MultiSelectExd', {
            submitValue: false,
            flex: 1,
            dragGroup: me.ddGroup,
            dropGroup: me.ddGroup,
            dragText: me.dragText,
            title: title,
            store: {
                model: me.store.model,
                data: []
            },
            displayField: me.displayField,
            valueField: me.valueField,
            disabled: me.disabled,
            listeners: {
                boundList: {
                    scope: me,
                    itemdblclick: me.onItemDblClick,
                    drop: me.syncValue
                }
            }
        });
    },
    getRecordsForValue: function (value, valueIsNull, mode) {
        var me = this,
            records = [],
            all = me.store.getRange(),
            valueField = me.valueField,
            i = 0,
            allLen = all.length,
            rec,
            j,
            valueLen;
        if (mode == "normal") {
            if (!valueIsNull) {
                if (this.selectAllOnEmptyValue && value.length == 0) {
                    for (j = 0; j < allLen; ++j) {
                        rec = all[j];
                        records.push(rec);
                    }
                } else {
                    for (valueLen = value.length; i < valueLen; ++i) {
                        for (j = 0; j < allLen; ++j) {
                            rec = all[j];
                            if (rec.get(valueField) == value[i]) {
                                records.push(rec);
                            }
                        }
                    }
                }
            } else {
                if (this.selectAllOnNullValue) {
                    for (j = 0; j < allLen; ++j) {
                        rec = all[j];
                        records.push(rec);
                    }
                }
            }
        } else if (mode == "clear") {
            records = [];
        } else if (mode == "all") {
            for (j = 0; j < allLen; ++j) {
                rec = all[j];
                records.push(rec);
            }
        }
        return records;
    },
    setupValue: function (value) {
        var delimiter = this.delimiter,
            valueField = this.valueField,
            i = 0,
            out,
            len,
            item;
        if (Ext.isDefined(value)) {
            if (delimiter && Ext.isString(value)) {
                value = value.split(delimiter);
            } else if (!Ext.isArray(value)) {
                value = [value];
            }
            for (len = value.length; i < len; ++i) {
                item = value[i];
                if (item && item.isModel) {
                    value[i] = item.get(valueField);
                }
            }
            out = Ext.Array.unique(value);
        } else {
            out = [];
        }
        return out;
    },
    setValue: function (value, mode) {
        var me = this,
            fromField = me.fromField,
            toField = me.toField,
            fromStore = fromField.store,
            toStore = toField.store,
            selected;
        if (!me.populateStorePopulated) {
            me.store.on({
                load: Ext.Function.bind(me.setValue, me, [value]),
                single: true
            });
            return;
        }
        var valueIsNull = false;
        if (value == null) {
            valueIsNull = true;
        }
        if (mode === undefined) {
            mode = "normal";
        }
        value = me.setupValue(value);
        me.mixins.field.setValue.call(me, value);
        selected = me.getRecordsForValue(value, valueIsNull, mode);
        fromStore.suspendEvents();
        toStore.suspendEvents();
        fromStore.removeAll();
        toStore.removeAll();
        me.populateStore(me.store);
        Ext.Array.forEach(selected, function (rec) {
            if (fromStore.indexOf(rec) > -1) {
                fromStore.remove(rec);
            }
            toStore.add(rec);
        });
        fromStore.resumeEvents();
        toStore.resumeEvents();
        Ext.suspendLayouts();
        fromField.boundList.refresh();
        toField.boundList.refresh();
        Ext.resumeLayouts(true);
    },
    getValue: function () {
        return this.value || [];
    },
    clearValue: function () {
        this.setValue([], "clear");
    },
    selectAllValue: function () {
        this.setValue([], "all");
    },
    moveRec: function (add, recs) {
        var me = this,
            fromField = me.fromField,
            toField = me.toField,
            fromStore = add ? fromField.store : toField.store,
            toStore = add ? toField.store : fromField.store;
        fromStore.suspendEvents();
        toStore.suspendEvents();
        fromStore.remove(recs);
        toStore.add(recs);
        fromStore.resumeEvents();
        toStore.resumeEvents();
        fromField.boundList.refresh();
        toField.boundList.refresh();
        me.syncValue();
    },
    syncValue: function () {
        var me = this;
        var returnRecordsRange = me.toField.store.getRange();
        if (this.returnEmptyOnAllSelected && me.fromField.store.getCount() == 0) {
            returnRecordsRange = [];
        }
        me.mixins.field.setValue.call(me, me.setupValue(returnRecordsRange));
    },
    onBtnSelectAll: function () {
        var me = this;
        me.selectAllValue();
    },
    onBtnClearAll: function () {
        var me = this;
        me.clearValue();
    },
    onBtnSelectTo: function () {
        var sels = this.fromField.getSelected();
        if (sels.length > 0) {
            this.moveRec(true, sels);
        }
    },
    onBtnSelectFrom: function () {
        var sels = this.toField.getSelected();
        if (sels.length > 0) {
            this.moveRec(false, sels);
        }
    },
    onItemDblClick: function (view, rec) {
        this.moveRec(view === this.fromField.boundList, rec);
    },
    onBindStore: function (store, initial) {
        var me = this;
        if (this.fromField) {
            me.fromField.store.removeAll();
            me.toField.store.removeAll();
            if (store.getCount()) {
                me.populateStore(store);
            } else {
                me.store.on('load', me.populateStore, me);
            }
        }
    },
    populateStore: function (store) {
        var fromStore = this.fromField.store;
        this.populateStorePopulated = true;
        fromStore.add(store.getRange());
    },
    getErrors: function (value) {
        var me = this,
            format = Ext.String.format,
            errors = [],
            numSelected;
        value = Ext.Array.from(value || me.getValue());
        numSelected = value.length;
        if (!me.allowBlank && numSelected < 1) {
            errors.push(me.blankText);
        }
        if (numSelected < me.minSelections) {
            errors.push(format(me.minSelectionsText, me.minSelections));
        }
        if (numSelected > me.maxSelections) {
            errors.push(format(me.maxSelectionsText, me.maxSelections));
        }
        return errors;
    },
    validateValue: function (value) {
        var me = this,
            errors = me.getErrors(value),
            isValid = Ext.isEmpty(errors);
        if (!me.preventMark) {
            if (isValid) {
                me.clearInvalid();
            } else {
                me.markInvalid(errors);
            }
        }
        return isValid;
    },
    isValid: function () {
        var me = this,
            disabled = me.disabled,
            validate = me.forceValidation || !disabled;
        return validate ? me.validateValue(me.value) : disabled;
    },
    markInvalid: function (errors) {
        var me = this,
            oldMsg = me.getActiveError();
        me.setActiveErrors(Ext.Array.from(errors));
        if (oldMsg !== me.getActiveError()) {
            me.updateLayout();
        }
    },
    clearInvalid: function () {
        var me = this,
            hadError = me.hasActiveError();
        me.unsetActiveError();
        if (hadError) {
            me.updateLayout();
        }
    },
    getSubmitValue: function () {
        var me = this,
            delimiter = me.delimiter,
            val = me.getValue();
        return Ext.isString(delimiter) ? val.join(delimiter) : val;
    },
    getSubmitData: function () {
        var me = this,
            data = null,
            val;
        if (!me.disabled && me.submitValue) {
            val = me.getSubmitValue();
            data = {};
            data[me.getName()] = '' + val;
        }
        return data;
    },
    onEnable: function () {
        var me = this;
        me.callParent();
        me.fromField.enable();
        me.toField.enable();
    },
    onDisable: function () {
        var me = this;
        me.callParent();
        me.fromField.disable();
        me.toField.disable();
    },
    onDestroy: function () {
        this.bindStore(null);
        this.callParent();
    }
});
