Ext.define('Ext.ux.form.field.ComboBoxExdSimpleId', {
    extend: 'Ext.ux.form.field.ComboBoxExd',
    alias: 'widget.comboexdsimpleid',
    displayField: 'text',
    valueField: 'value',
    forceSelection: true,
    allowBlank: false,
    defaultValue: null,
    initComponent: function () {
        this.store = {
            type: 'store',
            model: Ext.define(this.id + 'model_comboexdsimpleid', {
                extend: 'Ext.data.Model',
                idProperty: "id",
                fields: ['text', 'id']
            }),
            proxy: {
                type: 'memory',
                reader: {
                    type: "json"
                }
            }
        };
        this.callParent(arguments);
        if (this.value === null) {
            this.setValue2Default(this.defaultValue);
        }
    }
});
