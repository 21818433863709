Ext.define('Ext.form.field.ComboBoxFIX', {
    override: 'Ext.form.field.ComboBox',
    onFocus: function (e) {
        var me = this;
        if (Ext.isTouchMode() && me.getPicker().getEl()) {
            me.getPicker().getEl().focus();
        }
        me.callSuper([e]);
        if (me.triggerAction !== 'all' && me.queryFilter && me.queryMode === 'local' && me.clearFilterOnBlur) {
            delete me.lastQuery;
            me.doRawQuery();
        }
    }
});
/*
// Przykład z Override //
Ext.define('Ext.util.RenderableOverride', {
	override : 'Ext.util.Renderable',
	beforeRender : function() {
		return this.callParent(arguments);
	},
	initOverflowNew : function() {
	}
}, function() {
	var mixinId = this.prototype.mixinId;
	Ext.Object.each(Ext.ClassManager.classes, function(name, cls) {
		if (cls.prototype && cls.prototype.mixins && cls.prototype.mixins.hasOwnProperty(mixinId)) {
			cls.prototype.beforeRender = this.prototype.beforeRender;
			cls.prototype.initOverflow = this.prototype.initOverflowNew;
		}
	}, this);
});
Ext.define('ComponentOverride', {
	override : 'Ext.Component',
	applyScrollable : function(scrollable, oldScrollable) {
		return this.callParent(arguments);
	}
});
*/