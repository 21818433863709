Ext.define('Ext.ux.grid.column.CheckColumnExd', {
    extend: 'Ext.grid.column.Column',
    alias: 'widget.checkcolumnexd',
    selfRednerer: true,
    constructor: function () {
        this.callParent(arguments);
    },
    processEvent: function (type, view, cell, recordIndex, cellIndex, e) {
        if (type == 'mousedown' || (type == 'keydown' && (e.getKey() == e.ENTER || e.getKey() == e.SPACE))) {
            var record = view.panel.store.getAt(recordIndex);
            if (this.isCellEditable(record)) {
                var dataIndex = this.dataIndex,
                    checked = !record.get(dataIndex);
                record.set(dataIndex, checked);
                this.fireEvent('checkchange', this, recordIndex, checked);
                return false;
            } else {
                return this.callParent(arguments);
            }
        } else {
            return this.callParent(arguments);
        }
    },
    renderer: function (value, metaCls, record, rowi, coli, store, view) {
        var cm = view.getHeaderCt();
        var meCol = cm.getHeaderAtIndex(coli);
        if (meCol.selfRednerer) {
            if (meCol.isCellDisabled(record)) {
                return '' + '<div class="x-checkboxexd-grid-col-render">' + '<div class="' + (value ? "x-checkboxexd-checked-disabled-render" : "x-checkboxexd-unchecked-disabled-render") + '"' + '/>' + '</div>';
            } else {
                return '' + '<div class="x-checkboxexd-grid-col-render">' + '<div class="' + (value ? "x-checkboxexd-checked-render" : "x-checkboxexd-unchecked-render") + '"' + '/>' + '</div>';
            }
        } else {
            var cssPrefix = Ext.baseCSSPrefix,
                cls = [cssPrefix + 'grid-checkheader'];
            if (value) {
                cls.push(cssPrefix + 'grid-checkheader-checked');
            }
            return '<div class="' + cls.join(' ') + '">&#160;</div>';
        }
    },
    isCellEditable: function (record) {
        return false;
    },
    isCellDisabled: function (record) {
        return false;
    }
});
