Ext.define('Ext.ux.layout.container.CardExd', {
    extend: 'Ext.layout.container.Card',
    alternateClassName: 'Ext.layout.CardLayoutExd',
    alias: 'layout.cardexd',
    type: 'cardexd',
    animationExd: true,
    animationTimeD: 200,
    setActiveItemExd: function (newCard, fncc, fncs) {
        var me = this,
            owner = me.owner,
            oldCard = me.activeItem,
            rendered = owner.rendered,
            newIndex,
            focusNewCard;
        newCard = me.parseActiveItem(newCard);
        newIndex = owner.items.indexOf(newCard);
        oldIndex = owner.items.indexOf(oldCard);
        if (newIndex === -1) {
            newIndex = owner.items.items.length;
            Ext.suspendLayouts();
            newCard = owner.add(newCard);
            Ext.resumeLayouts();
        }
        if (newCard && oldCard !== newCard) {
            if (newCard.fireEvent('beforeactivate', newCard, oldCard) === false) {
                return false;
            }
            if (oldCard && oldCard.fireEvent('beforedeactivate', oldCard, newCard) === false) {
                return false;
            }
            if (rendered) {
                if (oldCard && this.animationExd) {
                    var oldCardEl = oldCard.getEl();
                    var directionNextAction = newIndex > oldIndex;
                    oldCardEl.slideOut(directionNextAction ? 'l' : 'r', {
                        duration: this.animationTimeD,
                        callback: function () {
                            if (rendered) {
                                Ext.suspendLayouts();
                                if (!newCard.rendered) {
                                    me.renderItem(newCard, me.getRenderTarget(), owner.items.length);
                                }
                                if (oldCard) {
                                    if (me.hideInactive) {
                                        focusNewCard = oldCard.el.contains(Ext.Element.getActiveElement());
                                        oldCard.hide();
                                        if (oldCard.hidden) {
                                            oldCard.hiddenByLayout = true;
                                            oldCard.fireEvent('deactivate', oldCard, newCard);
                                        } else {
                                            return false;
                                        }
                                    }
                                }
                                if (newCard.hidden) {
                                    newCard.show();
                                }
                                if (newCard.hidden) {
                                    me.activeItem = newCard = null;
                                } else {
                                    me.activeItem = newCard;
                                    if (focusNewCard) {
                                        if (!newCard.defaultFocus) {
                                            newCard.defaultFocus = ':focusable';
                                        }
                                        newCard.focus();
                                    }
                                }
                                Ext.resumeLayouts(true);
                            } else {
                                me.activeItem = newCard;
                            }
                            newCard.fireEvent('activate', newCard, oldCard);
                            Ext.defer(function () {
                                if (fncc != null) {
                                    fncc.call(fncs);
                                }
                            }, 1, this);
                            return me.activeItem;
                        }
                    });
                } else {
                    if (rendered) {
                        Ext.suspendLayouts();
                        if (!newCard.rendered) {
                            me.renderItem(newCard, me.getRenderTarget(), owner.items.length);
                        }
                        if (oldCard) {
                            if (me.hideInactive) {
                                focusNewCard = oldCard.el.contains(Ext.Element.getActiveElement());
                                oldCard.hide();
                                if (oldCard.hidden) {
                                    oldCard.hiddenByLayout = true;
                                    oldCard.fireEvent('deactivate', oldCard, newCard);
                                } else {
                                    return false;
                                }
                            }
                        }
                        if (newCard.hidden) {
                            newCard.show();
                        }
                        if (newCard.hidden) {
                            me.activeItem = newCard = null;
                        } else {
                            me.activeItem = newCard;
                            if (focusNewCard) {
                                if (!newCard.defaultFocus) {
                                    newCard.defaultFocus = ':focusable';
                                }
                                newCard.focus();
                            }
                        }
                        Ext.resumeLayouts(true);
                    } else {
                        me.activeItem = newCard;
                    }
                    newCard.fireEvent('activate', newCard, oldCard);
                    Ext.defer(function () {
                        if (fncc != null) {
                            fncc.call(fncs);
                        }
                    }, 1, this);
                    return me.activeItem;
                }
            } else {
                me.activeItem = newCard;
                newCard.fireEvent('activate', newCard, oldCard);
                Ext.defer(function () {
                    if (fncc != null) {
                        fncc.call(fncs);
                    }
                }, 1, this);
            }
            return me.activeItem;
        }
        return false;
    }
});
