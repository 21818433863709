Ext.define('Ext.ux.panel.PanelCardExd', {
    extend: 'Ext.panel.Panel',
    alias: 'widget.panelcardexd',
    config: {
        collapseExd: false
    },
    layout: {
        type: 'cardexd'
    },
    initComponent: function () {
        this.callParent(arguments);
    },
    applyCollapseExd: function (v) {
        Ext.defer(function () {
            if (v) {
                this.collapse();
            } else {
                this.expand();
            }
        }, 1, this);
        return v;
    },
    nextC: function () {
        this.getLayout().next();
    },
    prevC: function () {
        this.getLayout().prev();
    },
    setActiveItem: function (v) {
        this.getLayout().setActiveItemExd(v);
    },
    setView: function (v) {
        this.getLayout().setActiveItemExd(v);
    },
    pushView: function (v, c, s) {
        this.fireEvent("beforePushView", this, v, c, s);
        var items = this.getLayout().getLayoutItems();
        var fo = null;
        for (var i = 0; i < items.length; i++) {
            var o = items[i];
            if (v == o) {
                fo = o;
            }
            if (v.id && o.id && v.id == o.id) {
                fo = o;
            }
            if (v.itemId && o.itemId && v.itemId == o.itemId) {
                fo = o;
            }
        }
        if (fo == null) {
            fo = this.add(v);
        }
        this.getLayout().setActiveItemExd(fo, c, s);
        this.fireEvent("afterPushView", this, v, c, s);
        return fo;
    },
    popView: function (v, paramToEventCallV) {
        this.fireEvent("beforePopView", this, v);
        var items = this.getLayout().getLayoutItems();
        var fo = null;
        if (v != null) {
            for (var i = 0; i < items.length; i++) {
                var o = items[i];
                if (v == o) {
                    fo = o;
                }
                if (v.id && o.id && v.id == o.id) {
                    fo = o;
                }
                if (v.itemId && o.itemId && v.itemId == o.itemId) {
                    fo = o;
                }
            }
        } else {
            if (items.length >= 1) {
                fo = items[items.length - 1];
            }
        }
        if (fo != null) {
            if (items.length >= 2) {
                var di = items[items.length - 2];
                if (di == fo) {
                    di = items[items.length - 1];
                }
                this.getLayout().setActiveItemExd(di, function () {
                    this.remove(fo);
                    this.fireEvent("afterPopViewCallback", this, v, fo, paramToEventCallV);
                }, this);
            } else {
                this.remove(fo);
                this.fireEvent("afterPopViewCallback", this, v, fo, paramToEventCallV);
            }
        } else {
            this.fireEvent("afterPopViewCallback", this, v, null, paramToEventCallV);
        }
        this.fireEvent("afterPopView", this, v, paramToEventCallV);
    }
});
