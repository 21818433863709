Ext.define('Ext.ux.grid.GridPanelExd', {
    extend: 'Ext.grid.GridPanel',
    alias: 'widget.gridpanelexd',
    preserveScrollAll: true,
    enableTextSelection: true,
    initComponent: function () {
        this.viewConfig = this.viewConfig || {};
        Ext.applyIf(this.viewConfig, {
            preserveScrollOnRefresh: this.preserveScrollAll,
            preserveScrollOnReload: this.preserveScrollAll,
            enableTextSelection: this.enableTextSelection
        });
        this.callParent(arguments);
    }
});
