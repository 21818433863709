Ext.define('Ext.ux.RaphaelBaseComponentExd', {
    extend: 'Ext.Component',
    cls: "x-boilerplate",
    bgCls: "x-raphaelbasecomponentexd-background",
    frCls: "x-raphaelbasecomponentexd-frontend",
    initComponent: function () {
        this.cid = this.id;
        this.callParent(arguments);
    },
    afterRender: function () {
        this.callParent(arguments);
        this.bgEl = this.el.createChild({
            tag: 'div',
            cls: this.bgCls
        });
        this.frEl = this.el.createChild({
            tag: 'div',
            cls: this.frCls
        });
        this.canvas = Raphael(this.frEl.dom, this.getWidth(), this.getHeight());
        this.initCanvas();
        this.drawCanvasHand();
    },
    onResize: function (width, height, oldWidth, oldHeight) {
        this.callParent(arguments);
        this.bgEl.setSize(this.getWidth(), this.getHeight());
        this.frEl.setSize(this.getWidth(), this.getHeight());
        this.canvas.setSize(this.getWidth(), this.getHeight());
        this.reDrawCanvasHand();
    },
    onDestroy: function () {
        this.canvas.clear();
        Ext.destroy(this.bgImg);
        Ext.destroy(this.frEl);
        this.callParent(arguments);
    },
    initCanvas: function () {
        this.frEl.on("dblclick", this.dblclickCanvas, this);
        this.frEl.on("click", this.clickCanvas, this);
    },
    drawCanvasHand: function () {
    },
    reDrawCanvasHand: function () {
    },
    clickCanvas: function (e, t, eOpts) {
        var cx = e.getX() - this.frEl.getX();
        var cy = e.getY() - this.frEl.getY();
    },
    dblclickCanvas: function (e, t, eOpts) {
        var cx = e.getX() - this.frEl.getX();
        var cy = e.getY() - this.frEl.getY();
    }
});

function raphaelConvertToPath(arc) {
    var s = "";
    for (var i = 0; i < arc.length; i++) {
        var p = arc[i];
        if (p == "Z") {
            s += " Z";
        } else if (p[0] == "M") {
            s += " " + p[0] + +p[1] + "," + p[2];
        } else if (p[0] == "L") {
            s += " " + p[0] + +p[1] + "," + p[2];
        } else {
            s += " " + p[0] + +p[1] + "," + p[2];
        }
    }
    return s;
}
