Ext.define("App.WebSocketCzytnikPastylka", {
    extend: "Ext.util.Observable",
    urlToDownloadDeviceClient: "rest/systemreq/downloadAppStore?name=deviceclient.exe",
    langCzytnik: Lang.czytnik,
    langTekstDownloadUpgradeSoftCzytnik: Lang.prosze_o_aktualizacje_oprogramowania_do_komunikacji_z_urzadzeniem,
    version: {},
    versionCurrentClient: "4.2.52",
    versionCurrentDeviceClient: null,
    constructor: function (config) {
        this.callParent(arguments);
    },
    firstOkFired: false,
    start: function () {
        this.fireEvent("statusSysChange", this, null);
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
        this.openfirst = false;
        this.ws = new App.WebSocketObject({});
        this.ws.on({
            scope: this,
            open: this.openedWS,
            close: this.closedWS,
            message: this.messageWS
        });
        this.ws.open();
    },
    stop: function () {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
        this.openfirst = false;
    },
    send: function (m) {
        this.ws.send(m);
    },
    openedWS: function () {
        this.onOpenWS();
        this.fireEvent("open", this);
        if (!this.openfirst) {
            this.openfirst = true;
            this.fireEvent("openfirst", this);
        }
    },
    closedWS: function () {
        this.fireEvent("noservice", this, null);
        this.onCloseWS();
    },
    messageWS: function (c, d, e, id) {
        var d = Ext.decode(d);
        if (d.cmd == "statussys") {
            this.onStatusReceiveSys(d);
        } else if (d.cmd == "statusop") {
            this.onStatusReceiveOP(d);
        } else {
            if (d.cls == "localidle") {
                this.onLocalIdleReceive(d);
            } else if (d.cls == "smdprfd") {
                this.onSmdpRFDReceive(d);
            }
        }
    },
    onStatusReceiveSys: function (d) {
        if (d.status == "ok" && !this.firstOkFired) {
            this.firstOkFired = true;
            return;
        }
        this.fireEvent(d.status, this, null);
        if (d.version) {
            this.version[d.cls] = d.version;
            this.versionCurrentDeviceClient = d.version;
            if (this.versionCurrentDeviceClient != this.versionCurrentClient) {
                this.fireEvent("incorrectversion", this, null);
                if (!this.versionMismatchAlerted) {
                    this.versionMismatchAlerted = true;
                    this.handleVersionUpgradeF();
                }
            }
        }
    },
    onStatusReceiveOP: function (d) {
        this.fireEvent(d.status, this, null);
    },
    handleVersionUpgradeF: function () {
        var w = new Ext.window.Window({
            title: this.langCzytnik,
            layout: "fit",
            items: [{
                xtype: "box",
                style: "padding:20px;",
                html: {
                    tag: "a",
                    href: this.urlToDownloadDeviceClient,
                    html: this.langTekstDownloadUpgradeSoftCzytnik
                }
            }]
        });
        w.show();
    },
    onLocalIdleReceive: function (d) {
        this.fireEvent("localidleMessage", this, d);
    },
    onSmdpRFDReceive: function (d) {
        if (d.cmd == "ident") {
            this.ident = d.ident;
            this.identrwblocksize = d.rwblocksize;
            this.identrwsize = d.rwsize;
            this.identisrw = d.isrw;
            if (d.ident) {
                this.fireEvent("identput", this, d);
            } else {
                this.fireEvent("identpull", this, d);
            }
        }
        if (d.cmd == "readdata") {
            this.rwopData = d.data;
            this.fireEvent("readdata", this, d.data);
        }
        if (d.cmd == "progress") {
            this.rwopProgress = d.progress;
            this.fireEvent("progress", this, d.progress);
        }
    },
    onOpenWS: function () {
        this.abortAll();
    },
    onCloseWS: function () {
    },
    init: function () {
        this.rwopType = null;
        this.rwopProgress = null;
        this.rwopData = null;
        this.start();
    },
    close: function () {
        this.stop();
    },
    abortAll: function () {
        this.rwopType = null;
        this.rwopProgress = null;
        this.rwopData = null;
        this.ws.send({
            cls: "smdprfd",
            cmd: "abortall"
        });
    },
    setBlockDataToRead: function (dataSizeToRead, offsetReadWriteOp) {
        this.rwopType = "read";
        this.rwopProgress = null;
        this.rwopData = null;
        this.ws.send({
            cls: "smdprfd",
            cmd: "readdata",
            offset: offsetReadWriteOp,
            datasize: dataSizeToRead
        });
    },
    setBlockDataToWrite: function (input, offsetReadWriteOp) {
        this.rwopType = "write";
        this.rwopProgress = null;
        this.rwopData = null;
        this.ws.send({
            cls: "smdprfd",
            cmd: "writedata",
            data: input,
            offset: offsetReadWriteOp
        });
    }
});

// do usunięcia i przejścia na lokalne zmienne
window.managerCzytnikPastylka = new App.WebSocketCzytnikPastylka();
