Ext.define('Ext.ux.GMapPanelExd', {
    extend: 'Ext.panel.Panel',
    alias: 'widget.gmappanelexd',
    requires: ['Ext.window.MessageBox'],
    initComponent: function () {
        Ext.applyIf(this, {
            plain: true,
            gmapType: 'map',
            border: false
        });
        this.callParent();
    },
    onBoxReady: function () {
        this.callParent(arguments);
        this.initMap();
    },
    afterComponentLayout: function (w, h) {
        this.callParent(arguments);
        this.redraw();
    },
    redraw: function () {
        var map = this.gmap;
        if (map) {
            google.maps.event.trigger(map, 'resize');
        }
    },
    initMap: function () {
        if (!this.center) {
            this.center = new google.maps.LatLng(0, 0);
        }
        var center = this.center;
        if (center.geoCodeAddr) {
            this.lookupCodeMap(center.geoCodeAddr, center.marker);
        } else {
            this.createMap(center);
        }
    },
    createMap: function (center, marker) {
        var options = Ext.apply({}, this.mapOptions);
        options = Ext.applyIf(options, {
            zoom: 1,
            center: center,
            mapTypeId: google.maps.MapTypeId.HYBRID
        });
        this.gmap = new google.maps.Map(this.body.dom, options);
        if (marker) {
            this.addMarker(Ext.applyIf(marker, {
                position: center
            }));
        }
        Ext.each(this.markers, this.addMarker, this);
        this.fireEvent('mapready', this, this.gmap);
    },
    lookupCodeMap: function (addr, marker) {
        this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode({
            address: addr
        }, Ext.Function.bind(this.onLookupCompleteMap, this, [marker], true));
    },
    onLookupCompleteMap: function (data, response, marker) {
        if (response != 'OK') {
            Ext.MessageBox.alert('Error', 'An error occured: "' + response + '"');
            return;
        }
        this.createMap(data[0].geometry.location, marker);
    },
    addMarker: function (marker) {
        marker = Ext.apply({
            map: this.gmap
        }, marker);
        if (!marker.position) {
            marker.position = new google.maps.LatLng(marker.lat, marker.lng);
        }
        var o = new google.maps.Marker(marker);
        Ext.Object.each(marker.listeners, function (name, fn) {
            google.maps.event.addListener(o, name, fn);
        });
        return o;
    },
    addMarkerByAddress: function (addr, marker) {
        this.lookupCodeMarker(addr, marker);
    },
    lookupCodeMarker: function (addr, marker) {
        this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode({
            address: addr
        }, Ext.Function.bind(this.onLookupCompleteMarker, this, [marker], true));
    },
    onLookupCompleteMarker: function (data, response, marker) {
        if (data && data[0] && data[0].geometry) {
            var position = data[0].geometry.location;
            marker.position = position;
            this.addMarker(marker);
        }
    }
});
